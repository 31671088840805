import React, { useEffect, useRef } from 'react';
import './styles.scss';
import committeeCardInfo from '../../Data/committeeCardsInfo';
import leftArrowBtn from "../../assets/logos/left-button.svg";
import rightArrowBtn from "../../assets/logos/right-button.svg";

const MembersListContainer = ({membersDataIndex}) => {

  const duration = 500; // ms
  const delay = 100; // ms
  const ref = useRef();
  useEffect(()=>{
    ref.current.scrollTo({ top: 0, behavior: "smooth" });
  })

  const animStr = (i) => `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards`;

  return (
    <div ref={ref} className="member-list-container w-[95%] lg:w-[60%] mt-3 lg:mt-5  lg:max-h-[600px] h-3/5 flex-1 bg-red-200 flex item-center justify-center p-5  md:p-6 lg:p-10 overflow-scroll" >
    <div className="inner-container  h-auto w-full  flex justify-center flex-wrap">
    {
      committeeCardInfo[membersDataIndex].membersData.map((i , count)=>{
        return         <div className="individual-card h-[215px] rounded-xl overflow-hidden relative cursor-pointer" style={{ animation: animStr(count) }} key={Math.random()}>
                          <div className='fade-bg absolute h-full w-full z-10' ></div>
                          <img src={i.imageUrl} className="h-full w-full object-cover "  alt="membersimage"/>
                          <p className="member-name absolute bottom-0 z-20 text-white text-center text-sm w-full p-2 font-redRose">{i.memberName}</p>
                      </div>
      })
    }
  </div>
  <img
    className="absolute hidden lg:block top-[45%] left-[10%] max-h-[60px] cursor-pointer"
    src={leftArrowBtn}
    alt="left arrow button"
    id="swiper-button-prev"/>
   <img
    className="absolute top-[45%] hidden lg:block right-[10%] max-h-[60px] cursor-pointer"
    src={rightArrowBtn}
    alt="right arrow button"
    id="swiper-button-next"
  />
    
  </div>
  );
};

export default MembersListContainer;