import React, { useState } from 'react'
import "./style.scss"
import Hamburger from 'hamburger-react';
import cngLogo from '../../assets/logos/codesNgearsText.svg';
import cdwLogo from '../../assets/logos/cdwLogo.webp';
import HomepageButton from '../HomepageButton';
import { APP_CONSTANTS } from '../../constants/app.constants';
import { useNavigate } from 'react-router';


const HomepageNavbar = (props) => {
    const navigate  = useNavigate();
    const [faq, setFaq] = useState(false); // props.displayFaq

  const { handleButtonClick , displayFaq } = props;
  function openSideNavbar(){
        handleButtonClick();
  }

  return (
    <div className='header px-4 xl:px-6'>   
        <div className='hamburger-icon'>
            <Hamburger toggle={()=>{openSideNavbar()}}/>
        </div>
        <div className='cursor-pointer cng-logo  w-2/5 md:w-44 lg:w-48 xl:w-3/6' onClick={()=>{navigate('/')}}>
            <img src={cngLogo} alt='codes-logo' />
        </div>
        <div className='cdw-logo'>
            {/* {faq && 
                <>
                    <div className='mobile-faq-logo'>?</div>
                    <div className='faq-button'>
                        <HomepageButton name={'FAQ'} logoPosition={'right'} logo='?' />
                    </div>
                </>
            }*/}
        <img src={cdwLogo} alt='logo' onClick={()=>{window.open(APP_CONSTANTS.NAVBAR_COMPONENTS.cdw_website, "_blank", "noreferrer");}} className='cursor-pointer'/>
        </div> 
    </div>
  )
}

export default HomepageNavbar;