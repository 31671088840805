import React from 'react';
import './styles.scss';
const AntecedentsNameContainer = ({year,imageUrl})=>{

    return(
        <div className = "lg:h-[187px] lg:w-[743px] h-[115px] w-[338px] md:w-[538px] md:h-[150px] content-center font-goodTimes relative">
            <div className="w-[127px] h-[100px] md:w-[180px] md:h-[147px] md:left-[180px] md:top-[0px] top-[12px] left-[90px] rounded-[40px] lg:w-[193px] lg:h-[187px] lg:rounded-[40px] absolute lg:left-[200px] lg:top-[0px] image-container">
                <img src={imageUrl} alt="cng-2022" className="opacity-30 rounded-[40px]"/>
            </div>
            <div className="w-[298px] h-[24px] text-[20px] leading-[24px] tracking-[19px] space-x-[18px] top-[50px] left-[21px] md:text-[40px] md:w-[538px] md:h-[100px] md:tracking-[30px] md:space-x-[40px] md:top-[60px] md:leading-[30px] md:left-[20px] lg:w-[596px] lg:h-[187px] absolute lg:top-[60px] lg:left-[30px] font-normal lg:text-[40px] lg:leading-[48px] flex flex-row lg:space-x-[35px] lg:tracking-[35px] bg-gradient-to-r from-light-blue to-light-grey text-transparent bg-clip-text">
                <div>#CNG</div>
                {/*<div>CNG</div>*/}
                <div>{year}</div>
            </div>
        </div>
    );

}
 export default AntecedentsNameContainer;
