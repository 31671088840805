import React,{useState,useEffect,useRef} from "react";
import "./styles.scss";
import * as THREE from 'three';
import WAVES from 'vanta/dist/vanta.waves.min';
import HomepageNavbar from "../../components/HomepageNavbar";
import SideNavbar from "../../components/SideNavbar";
import aIcon from "../../assets/images/A-icon.svg";
import leftArrow from "../../assets/logos/left-button.svg";
import rightArrow from "../../assets/logos/right-button.svg";
import BUTTON_CONSTANTS from "../../constants/button.constants";
import { WINNERS_DETAILS } from "../../constants/winners.constants";
import cngLogo from "../../assets/logos/cngLogo.png";
export default function Winners(){
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [slideIndex,setSlideIndex] = useState(0);
  const [activeDot, setActiveDot] = useState(0);
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  const winnersDetails = WINNERS_DETAILS;
  const winnersInfo = winnersDetails[slideIndex].members;
  const handleButtonClick = () => {
    setShowSideNavbar(true);
    setDisplayOverlay(true);
  };

  const handleNavigation = (direction) => {
    const currentIndex = slideIndex;
    const totalSlides = winnersDetails.length;
    
    let newIndex;

    if (direction === BUTTON_CONSTANTS.swiperCustomButtonConstants.NEXT) {
      newIndex = currentIndex === totalSlides - 1 ? 0 : currentIndex + 1;
    } else if (direction === BUTTON_CONSTANTS.swiperCustomButtonConstants.PREV) {
      newIndex = currentIndex === 0 ? totalSlides - 1 : currentIndex - 1;
    }
  
    setSlideIndex(newIndex);
    setActiveDot(newIndex+1);
  };

  const handleDotClick = (index)=>{
    setSlideIndex(index-1);
    setActiveDot(index);
  }
  var dotIterator = [];
  for(var i=1; i<=winnersDetails.length; i++) {
    dotIterator.push(i.toString());
  }
  useEffect(() => {
    if (!vantaEffect && myRef.current) {
      setVantaEffect(WAVES({
        el: myRef?.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 600.0,
        minWidth: 600.0,
        scale: 1.00,
        scaleMobile: 1.00,
        waveHeight:15,
        waveSpeed:1,
        color: 0xb0c0b,
        shininess: 20.00,
        zoom: 0.86,
        THREE:THREE
      }))
    }
    return () => {
      if (vantaEffect) { 
        vantaEffect.destroy();
      }
    };
  }, []);

  return(
    <div className="winners-container">
      <div ref={myRef} className="vanta-bg"></div>
      <HomepageNavbar handleButtonClick={handleButtonClick} />
      <SideNavbar showSideNavbar={showSideNavbar} displayOverlay={displayOverlay} setDisplayOverlay={setDisplayOverlay} setShowSideNavbar={setShowSideNavbar} />
      <div className="winners-wrapper">
        <div className="lg:w-[1000px] lg:h-[58px] md:w-auto md:h-auto w-[318.07px] h-[58.97px] flex lg:gap-x-[70px] md:gap-x-[50px] gap-x-[15px] mt-[90px] justify-center items-center mx-[auto] winners-top-container">
          <div className="lg:w-[198px] md:w-[150px] md:h-[0px] lg:h-[0px] w-[80px] h-[0px]  border-[10px] rounded-l-[15px] gradient-border"></div>
          <div className="font-goodTimes font-normal lg:text-[48px] lg:leading-[57.6px] md:text-[38px] md:leading-[50px] gradient-text">WINNERS</div>
          <div className="lg:w-[198px] lg:h-[0px] md:w-[150px] md:h-[0px] w-[80px]  h-[0px] border-[10px] rounded-r-[15px] gradient-border"></div>
        </div>
        <div className="lg:w-[936px] lg:h-[750px] w-[296px] h-[680px] winner-list-container flex flex-col justify-evenly mx-auto">
          <div className="text-center text-[#FFFFFF] lg:text-[30px] md:text-[30px] font-bold lg:leading-[29.98px] md:leading-[22.98px] font-redRose flex lg:w-[257px] lg:h-[52px] md:w-[257px] md:h-[52px] justify-evenly items-center mx-auto"> 
            {(winnersDetails[slideIndex].type === "thinkathon")?
            <div>THINK</div>:
            <div>CODE</div>
            }
            <div className="lg:h-[52px] h-[32px] md:h-[42px]">
              <img src={aIcon} alt=" a icon"className="w-[100%] h-[100%]"></img>
            </div>
            <div>
              THON
            </div>
          </div>

          <div className="lg:w-[936px] lg:h-[614px] md:w-[500px] md:h-[600px] w-[296px] h-[576px] winner-list-inner-container flex flex-col justify-around mx-auto md:gap-y-[20px]">
            <div className="font-goodTimes font-normal lg:text-[28px] md:text-[24px] text-[15px] lg:leading-[56.25px] md:leading-[46px] leading-[35px] gradient-text text-center">{winnersDetails[slideIndex].title}</div>
            <div className="winner-list-info flex flex-wrap lg:w-[790px] lg:h-[494px] md:w-auto w-[271px] h-[526px] lg:gap-x-[60px] lg:gap-y-[30px] md:gap-x-[30px] md:gap-y-[30px] gap-x-[30px] gap-y-[20px] mx-auto justify-center">
              {
              winnersInfo.map((data)=>(
              <div className="flex flex-col winner-list-card lg:w-[157px] lg:h-[220px] md:w-[120px] md:h-[200px] w-[105px] h-[147.5px] gap-y-[10px] items-center">
                <div className="image-container lg:w-[150px] lg:h-[150px] md:w-[120px] md:h-[120px] w-[100px] h-[100px]">
                  <img src={data.image} alt={`${data.name}'s image`}></img>
                </div>
                <div className="text-center text-[#FFFFFF] lg:text-[24px] md:text-[20px] font-normal lg:leading-[29.98px] font-redRose">{data.name}</div>
              </div>))
              }
            </div>
          </div>
        </div>
        <div className="flex lg:h-[42px] lg:w-auto md:h-[40px] md:w-[auto] h-[33px] w-auto mx-auto justify-center items-center gap-x-[15px] mt-[15px]">
          <div className="flex items-center justify-between lg:w-auto lg:h-[42px] md:h-[40px] md:w-[auto] h-[50px] w-auto lg:gap-x-[45px]  md:gap-x-[45px] gap-x-[25px] ">
            {dotIterator.map((index)=>(
              <div className="relative lg:h-[42px] lg:w-auto md:h-[42px] md:w-[auto]" key={index} onClick={()=>handleDotClick(index)}>
                <span className="dot lg:h-[15px] lg:w-[15px] md:h-[15px] md:w-[15px] h-[10px] w-[10px]"></span>
                  <div className="lg:w-[50px] lg:h-[42px] md:w-[50px] md:h-[42px] w-[39.29px] h-[33px] logo-container absolute lg:-top-[10px] md:-top-[10px] -left-[13px] -top-[4px]" style={{ visibility:((activeDot === index)||(slideIndex === index-1))?  'visible': 'hidden'}}>
                    <img src={cngLogo} alt="cngLogo" />
                  </div>
              </div>
            ))}
          </div>
          
        </div>
        <div className="custom-nav lg:visible md:visible invisible ">
          <div
            className="custom-prev-button"
            onClick={() => handleNavigation(BUTTON_CONSTANTS.swiperCustomButtonConstants.PREV)}
          >
            <img src={leftArrow} alt="Previous" className="w-8 cursor-pointer sm:w-9 md:w-[50px] md:h-[50px] lg:w-12"/>
          </div>
          <div
            className="custom-next-button"
            onClick={() => handleNavigation(BUTTON_CONSTANTS.swiperCustomButtonConstants.NEXT)}
          >
            <img src={rightArrow} alt="Next" className="w-8 cursor-pointer sm:w-9 md:w-[50px] md:h-[50px] lg:w-12"/>
          </div>
        </div>
      </div>
    </div>
  );
}