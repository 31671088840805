import './styles.scss';
import A_icon from "../../assets/images/A-icon.svg"
import React from 'react';
import greenGradient from "../../assets/images/EventPageAssets/greenGradientButton.svg"
import blueGradient from "../../assets/images/EventPageAssets/blueGradientButton.svg"

const EventsPageCard = ({type,eventDetails,date,timeSpan,prize,participants,name}) => {
    const BoxButton = ({icon , details, type}) => {

        return <>
        <div className='flex items-center card-button h-14 '>
            <img src={type === "codeathon" ? blueGradient : greenGradient } className='absolute w-full h-full'/>
            <div className='w-full  border-wrapper px-1 text-[9px] sm:text-[10px] font-bold flex  items-center justify-center gap-4 md:gap-5  '>
                <div className='h-[35px]  w-[25px]'>
                    <img className='w-full h-full' src={icon} alt='button icon' />
                </div>
                <span className='text-left max-w-[90px] lg:max-w-[200px] ' >
                    {details}
                </span>
            </div>
        </div>
    </>
    }

    return (<>
        <div className={`${type === "codeathon" ? 'codeathon' : 'thinkathon'} relative w-full flex-1 sm:h-full lg:max-w-[43%] flex flex-col border-box`}>
            <div className='z-10 banner backdrop-blur-sm'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div className='absolute top-0 flex items-center justify-center gap-3 text-2xl font-black text-white banner-text font-goodTimes '>
                    <div className='flex items-center justify-center'>{name}</div>
                    <div className='w-12 h-12 '>
                        <img className='w-full h-full' src={A_icon} alt='a-icon' />
                    </div>
                    <div className='flex items-center justify-center'>THON</div>
                </div>
            </div>
            <div className={`card-border  flex-1  w-full px-2 lg:px-5 flex flex-col  text-center text-white  mt-[200px] justify-between  lg:gap-0`}>
                <div className='flex flex-col flex-1 h-[30%] gap-10 lg:gap-0  mb-10 items-center justify-around  mt-[160px] font-redRose text-md md:text-md mb-4 px-4'>
                    {
                        eventDetails.map((data,index) => {
                            return <p key={index} className=' xl:text-md'>{data}</p>
                        })
                    }
                </div>
                <div className='flex w-full gap-4 mb-4 md:gap-5 font-goodTimes'>
                    <div className='flex flex-col flex-1 w-1/3 gap-4'>
                        <BoxButton {...date} type={type} />
                        <BoxButton {...timeSpan} type={type} />
                    </div>
                    <div className='flex flex-col flex-1 w-2/4 gap-4'>
                        <BoxButton {...prize} type={type} />
                        <BoxButton {...participants} type={type} />
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default EventsPageCard