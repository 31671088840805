import cng_2014 from "../assets/logos/logo_2014.webp";
import cng_2015 from "../assets/logos/logo_2015.webp";
import cng_2016 from "../assets/logos/logo_2016.webp";
import cng_2017 from "../assets/logos/logo_2017.webp";
import cng_2018 from "../assets/logos/logo_2018.webp";
import cng_2019 from "../assets/logos/logo_2019.webp";
import cng_2020 from "../assets/logos/logo_2020.webp";
import cng_2021 from "../assets/logos/logo_2021.webp";
import cng_2022 from "../assets/logos/logo_2022.webp";
import cng_2023 from "../assets/logos/logo_2023.webp";

export const ANTECEDENTS_NAME_DETAILS =[
    {
      "imageUrl":cng_2014,
      "year":2014
    },
    {
      "imageUrl":cng_2015,
      "year":2015
    },
    {
      "imageUrl":cng_2016,
      "year":2016
    },
    {
      "imageUrl":cng_2017,
      "year":2017
    },
    {
      "imageUrl":cng_2018,
      "year":2018
    },
    {
      "imageUrl":cng_2019,
      "year":2019
    },
    {
      "imageUrl":cng_2020,
      "year":2020
    },
    {
      "imageUrl":cng_2021,
      "year":2021
    },
    {
      "imageUrl":cng_2022,
      "year":2022
    },
    {
      "imageUrl":cng_2023,
      "year":2023
    }
];
  
export const ANTECEDENTS_DESCRIPTION_DETAILS = [
    {
      "imageUrl":cng_2014,
      "theme":"Future of",
      "caption":"2020",
      "description":"Ideas generated by keeping the vision for 2020 and how technologies would shift. Team developed ideas in IBM Watson, Smart TV and IoTs"
    },
    {
      "imageUrl":cng_2015,
      "theme":"NextGen",
      "caption":"Shopping",
      "description":"Innovation in IoT, AI, VR technologies, and the way it transformed the shopping experience for the next generation of customers"
    },
    {
      "imageUrl":cng_2016,
      "theme":"Future of",
      "caption":"e-commerce & mobile",
      "description":"Facebook released its AI plaform wit.ai, innovation on the conversational AI began. Machines started talking to humans through Sirius Innovation"
    },
    {
      "imageUrl":cng_2017,
      "theme":"What the",
      "caption":"hack",
      "description":"Wearable technology took the healthcare industry by storm. Tons of innovation and development"
    },
    {
      "imageUrl":cng_2018,
      "theme":"Save the",
      "caption":"World",
      "description":"Innovation happened in technologies like AI, IoT, BlockChain, VR & AR. Ideas changed the way of interacting with customers and dealing with the data"
    },
    {
      "imageUrl":cng_2019,
      "theme":"For the",
      "caption":"throne",
      "description":"Voice & Data transformed the IT landscape into a new Era. Ideas to start looking at data differently and voice to interact with applications"
    },
    {
      "imageUrl":cng_2020,
      "theme":"Venturing into",
      "caption":"space",
      "description":"Out of the world ideas from the diversified tech space explored, beyond the imagination"
    },
    {
      "imageUrl":cng_2021,
      "theme":"Talent Meets",
      "caption":"Opportunity",
      "description":"The vision becomes reality when the right talent is linked with the right opportunity"
    },
    {
      "imageUrl":cng_2022,
      "theme":"Metaverse-",
      "caption":"#BEHOLDTHEFUTURE",
      "description":"Immersive solutions from the Metaverse was brought to life by our Metazens"
    },
    {
      "imageUrl":cng_2023,
      "theme":"REBOOT-",
      "caption":"RETHINK-REINVENT",
      "description":"Witness how Fire and Water collide at this year's Annual Elite hackathon event. We are thinking Globally and Acting locally this year dissolving the finer boundaries that exist."
    },
];