import SingleTeam from '../SingleTeam';
import './styles.scss';
import React, { useEffect, useRef } from 'react';

const ParticipantsListContainer = ({details}) => {
  const ref = useRef();
  useEffect(()=>{
    ref.current.scrollTo({ top: 0, behavior: "smooth" });
  })
  return (
    <div className="participants-list-container h-full w-full flex flex-col gap-10 lg:gap-20 rounded-xl overflow-scroll  mb-4 p-4 md:p-8 " ref={ref}>
        {
            details.map( (el , count) => {
                return  <SingleTeam {...el} count = {count}/>
            })
        }
       
    </div>
  )
}

export default ParticipantsListContainer
