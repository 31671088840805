// AEM Checkmate
import Ashwin from "../assets/images/ParticipantsImages/ashwin.webp"
import Praveen_Gopal from "../assets/images/CommitteeImages/EngagementCommittee/praveen_gopa.webp";
import Thanushree from "../assets/images/CommitteeImages/foods_goodies/Thanu.webp"

// Witches and Wizards
import sarathy from "../assets/images/ParticipantsImages/GnanaSarathy.webp";
import mukesh from "../assets/images/ParticipantsImages/mukesh.webp";
import heamPriyaData from "../assets/images/ParticipantsImages/hemaPriya.webp";
import Akshaya from "../assets/images/ParticipantsImages/Akshaya.webp";
import christopher from "../assets/images/ParticipantsImages/christopher.webp";

// outofthebox
import Rakesh from "../assets/images/CommitteeImages/web_dev/Rakesh.webp";
import Sathish from "../assets/images/CommitteeImages/Creative-Commitee/sathish.webp";
import joel from "../assets/images/CommitteeImages/Creative-Commitee/joel.webp";
import gaurav from "../assets/images/ParticipantsImages/gavrav.webp";
import naveensnow from "../assets/images/ParticipantsImages/navvenSnow.webp"

import user from "../assets/images/ParticipantsImages/userPhoto.webp"

export const WINNERS_DETAILS = [
  {
    "title": "Out Of Box",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Gaurav Rai",
      image:gaurav
    },
    {
      name:"Naveen Gowthaman",
      image:naveensnow
    },
    {
      name:"Joel Moses",
      image:joel
    },
    {
      name:"Sathish Selvan",
      image:Sathish
    },{
      name:"Rakhesh Bowtham",
      image:Rakesh
    }
  ],
  },
  {
    "title": "Witches and Wizard",//Selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Mukesh Ravi",
      image:mukesh
    } ,
    {
      name:"Akshaya Dhanraj",
      image:Akshaya
    },
    {
      name:"Gnana Sarathy A",
      image:sarathy
    },
    {
      name:"Hemapriya Nagarajan",
      image:heamPriyaData
    },
    {
      name:"Christopher Marcolis",
      image:christopher
    },
  ]
  },
  {
    "title": "SNOW Squad",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Bosco Sahayaraj",
      image:user
    },
    {
      name:"Syed Multhazim",
      image:user
    },{
      name:"Maria Shyamala Abiraham",
      image:user
    }
    ,{
      name:"Priyadharshini Karupasamy",
      image:user
    },
    {
      name:"Namie Kanteti",
      image:user
    },],
  },
  {
    "title": "AEM Checkmate",//selected
    "status": true,
    "type":"thinkathon",
    "members": [{
      name:"Praveen gopal",
      image:Praveen_Gopal
    },
    {
      name:"Aswin kumar Marx",
      image:Ashwin
    },
    {
      name:"Thanushree Srinivasan",
      image:Thanushree
    },
  ],
  },
    
]