import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import PosterPage from "./pages/PosterPage";
import CommitteePage from "./pages/CommitteePage";
import StatusPage from "./pages/StatusPage";
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import JuiresPage from "./pages/JuriesPage";
import Antecedents from "./pages/AntecedentsPage";
import Winners from "./pages/WinnersPage";
import ParticipantsPage from "./pages/ParticipantsPage";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<HomePage />} />
      <Route path="/committeePage" element={<CommitteePage />} />
      <Route path="/statusPage" element={<ParticipantsPage />} />
      <Route path="/events" element={<EventsPage />} />
      <Route path="/juries" element={<JuiresPage />} />
      <Route path="/antecedents" element={<Antecedents />} />
      <Route path="/winners" element={<Winners />}/>
    </>
  )
);

export default router;
