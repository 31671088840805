import React, { useState } from "react";
import "./styles.scss"
import { SIDE_NAVBAR_DATA } from "../../constants/app.constants";
import facebookIcon from "../../assets/icons/facebook.svg";
import twitterIcon from "../../assets/icons/x.svg";
import instagramIcon from "../../assets/icons/instagram.svg";
import cngIcon from "../../assets/logos/codesNgears.svg";
import cngTextIcon from "../../assets/logos/codesNgearsText.svg";
import leftArrow from "../../assets/logos/left-button.svg";

import { useLocation, useNavigate } from "react-router";

const SideNavbar = (props) => {
    const { showSideNavbar, setShowSideNavbar, displayOverlay, setDisplayOverlay} = props;

    const navigate = useNavigate();
    const location = useLocation();

    const [highlightCurrentmenu, setHighlightCurrentmenu] = useState(location.pathname);
    const updateHighlightCurrentmenu = (path) => {
        setHighlightCurrentmenu(path);
        navigate(path);
    }

    const updateShowNavBarStatus = () => {
        setShowSideNavbar(false);
        setDisplayOverlay(false);
    } 

    return (
        <> 
            <div className={`side-navbar-overlay ${displayOverlay ? "showOverlay" : "hideOverlay"}`} onClick={updateShowNavBarStatus}></div>
            <div className="container relative">
                <div className={`side-navbar-container ${showSideNavbar ? "show" : "hide"}`}>
                    <div className={`side-navbar font-goodTimes`}>
                        <div className="side-navbar-logo-wrapper">
                            <div className="side-navbar-logo-icon">
                                <div className="side-navbar-logo-details cursor-pointer" onClick={()=>{
                                    navigate('/')
                                }}>
                                    <img src={cngIcon} alt="cng-icon"/>
                                    <img className="cng-text-icon" src={cngTextIcon} alt="cng-text"/>
                                </div>
                                <img className={`left-arrow-icon ${showSideNavbar ? "show" : "hide"}`} src={leftArrow} alt="left arrow icon" onClick={updateShowNavBarStatus} />
                            </div>
                        </div>
                        <ul className="side-navbar-links">
                            {SIDE_NAVBAR_DATA.map((navbarItem, index) => (
                                <li className="side-navbar-items cursor-pointer" key={index} onClick={() => updateHighlightCurrentmenu(navbarItem.urlPath)}>
                                    <img src={`./assets/sideNavbar/${navbarItem.imageUrl}`} alt={`${navbarItem.key} icons`} />
                                    <div className="side-navbar-item-name">
                                        <a>{navbarItem.label}</a>
                                        {highlightCurrentmenu === navbarItem.urlPath ? <div className="under relative"></div> : null}
                                        <div className="under hover-under relative"></div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="flex justify-center gap-6 side-navbar-icons-wrapper">
                            <div className="social-media-wrapper cursor-pointer">
                                <a href="https://twitter.com/codesngears" target="_blank">
                                    <img src={twitterIcon} alt="twitter icon" />
                                </a>
                            </div>
                            <div className="social-media-wrapper cursor-pointer">
                                <a href="https://www.instagram.com/codesngears/" target="_blank">
                                    <img src={instagramIcon} alt="instagram icon" />
                                </a>
                            </div>
                            <div className="social-media-wrapper cursor-pointer">
                                <a href="https://www.facebook.com/codesngears/" target="_blank">
                                    <img src={facebookIcon} alt="facebook icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )

}

export default SideNavbar;