import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import "./styles.scss";

import CommitteeCardCarousel from "../../components/CommitteCardCarousel";
import committeeCardInfo from "../../Data/committeeCardsInfo"
import MembersListContainer from "../../components/MembersListContainer/MembersListContainer";
import HomepageNavbar from "../../components/HomepageNavbar";
import SideNavbar from "../../components/SideNavbar";

const CommitteePage = () => {
  const [swiper, setSwiper] = useState(null);
  const [membersDataIndex, setMembersDataIndex] = useState(0);
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleButtonClick = () => {
    setShowSideNavbar(true);
    setDisplayOverlay(true);
  };

  return (
    <div className="committee-page-container">
      <HomepageNavbar handleButtonClick={handleButtonClick} />
      <SideNavbar showSideNavbar={showSideNavbar} displayOverlay={displayOverlay} setDisplayOverlay={setDisplayOverlay} setShowSideNavbar={setShowSideNavbar} />
      <div className="flex items-center h-full w-full flex-col  items-center mt-[80px]">
        <div className="flex items-center justify-center gap-2  mt-5">
          <div className="glow-bar-left"></div>
          <h1 className="text-gradient lg:text-3xl tracking-widest font-goodTimes">COMMITTEES</h1>
          <div className="glow-bar-right"></div>
        </div>
        <MembersListContainer membersDataIndex={membersDataIndex} />
        <div className="hidden md:flex justify-center w-full">
          <CommitteeCardCarousel committeeCardInfo={committeeCardInfo} setSwiper={setSwiper} setMembersDataIndex={setMembersDataIndex} />
        </div>

        <div className="block md:hidden w-full">
          <CommitteeCardCarousel committeeCardInfo={committeeCardInfo} setMembersDataIndex={setMembersDataIndex} />
        </div>
      </div>
    </div>
  );
};

export default CommitteePage
