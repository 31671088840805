import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../../assets/images/PreviousMomentsAssets/img1.webp";
import img2 from "../../assets/images/PreviousMomentsAssets/img2.webp";
import img3 from "../../assets/images/PreviousMomentsAssets/img3.webp";
import img4 from "../../assets/images/PreviousMomentsAssets/img4.webp";
import img5 from "../../assets/images/PreviousMomentsAssets/img5.webp";
import img6 from "../../assets/images/PreviousMomentsAssets/img6.webp";
import img7 from "../../assets/images/PreviousMomentsAssets/img7.webp";
import img8 from "../../assets/images/PreviousMomentsAssets/img8.webp";
import img9 from "../../assets/images/PreviousMomentsAssets/img9.webp";


import rightArrow from "../../assets/logos/right-button.svg";
import "./styles.scss";

export default function PrevMoments() {
  const navigate = useNavigate();
  let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
  const handleNavigationToAntecedents = () => {
    // Navigate to the /events page when the button is clicked
    navigate("/antecedents");
  }

  images = images.map((image, idx) => (
    <div  key={idx} className="relative min-w-[100px] h-[100px] mx-2 lg:min-w-[300px] lg:h-[300px] lg:mx-8">
      <img
        key={idx}
        className={`w-full h-full object-cover`}
        src={image}
        alt={"image " + idx}
      />
    </div>
  ));
  return (
    <section className="flex flex-col gap-2.5 w-full px-8 py-8 h-[300px] lg:h-[400px] xl:py-12 xl:h-[500px] lg:px-14 overflow-hidden prev-moments-section">
      <div className="flex items-center justify-between">
        <h1 className="text-[8px] font-extrabold tracking-wider sm:text-sm md:text-lg lg:text-lg xl:text-xl txt-gradient-b-g font-goodtimes">
          #YOURTECHFEST
        </h1>
        <div className="flex items-center gap-2 md:gap-3">
          <div className="w-3 h-0.5 sm:w-4 md:h-[2.5px] bg-gradient-to-r from-malachite to-light-blue rounded-l-full"></div>
          <h1 className="text-white text-[6px] sm:text-xs">KNOW MORE</h1>
          <img
            className="w-4 cursor-pointer sm:w-7 md:w-8 lg:w-8"
            src={rightArrow}
            alt="right arrow button"
            onClick={handleNavigationToAntecedents}
          />
        </div>
      </div>
      <div className="relative flex w-full max-w-[400px] mx-auto sm:max-w-[700px] md:max-w-[800px] lg:max-w-[1200px] xl:max-w-[1600px] content">
        <div className="absolute z-10 sm:w-[250%] md:w-[200%] h-full -mx-8 lg:-mx-14 flex flex-row items-center self-center">
          <div className="relative flex flex-row items-center justify-around w-full h-full images">
            {images}
          </div>
          <div className="relative flex flex-row items-center justify-around w-full h-full images">
            {images}
          </div>
        </div>

        <div className="relative w-full h-full text-3xl sm:text-6xl md:7xl lg:text-8xl xl:text-9xl">
          <h1 className="absolute z-20 flex flex-col items-center self-center justify-center w-full text-white font-goodTimes txt-overlay center-position">
            <span className="self-start">PREVIOUS</span>
            <span className="self-end">MOMENTS</span>
          </h1>
          <h1 className="absolute flex flex-col items-center self-center justify-center w-full text-white font-goodTimes center-position">
            <span className="self-start">PREVIOUS</span>
            <span className="self-end">MOMENTS</span>
          </h1>
        </div>
      </div>
    </section>
  );
}
