import { React } from "react";
import logoImage from "../../assets/logos/codesNgearsText.svg";
import mainLogoImage from "../../assets/logos/codesNgears.svg";
import "./styles.scss";

export default function HomeFooter() {
    return (
        <footer className="flex flex-col items-center justify-center w-full h-32 gap-y-2 md:flex-row md:justify-between md:px-8 lg:px-14 home-footer">
            <div className="flex justify-between gap-3 lg:gap-8">
                <div className="max-w-[60px] md:max-w-[80px] lg:max-w-[100px]">
                    <img
                        className="object-contain w-full h-full"
                        src={mainLogoImage}
                        alt="Codes and Gears - 2024"
                    />
                </div>
                <div className="max-w-[150px] md:max-w-[250px] lg:max-w-[300px] ">
                    <img
                        className="object-contain w-full h-full"
                        src={logoImage}
                        alt="Codes and Gears - 2024"
                    />
                </div>
            </div>
            <p className="text-base font-semibold tracking-widest text-white md:text-xl lg:text-2xl font-orbitron">
                &lt;go-<span className="text-green">green</span> /&gt;
            </p>
        </footer>
    );
}
