import React from "react";
import './styles.scss';
const AntecedentsDescriptionContainer = ({theme,caption,description,imageUrl})=>{
    
    return(
        <div className="text-[#FFFFFF] lg:w-[1125px] lg:h-[186.39px] flex flex-col gap-[5px] lg:flex lg:flex-row lg:gap-[50px] font-goodTimes w-[330px] h-[276px] md:flex md:flex-row md:w-[800px] md:h-[230px] md:gap-[60px] md:top-[20px] description-container">
          <div className="image-container lg:w-[151px] lg:h-[146px] w-[100px] h-[90px] lg:static relative left-[110px] md:left-[0px] md:w-[135px] md:h-[135px] md:top-[10px]">
           <img src={imageUrl} alt="cng 22 logo" className="rounded-[40px]"/>
          </div>
          <div className="flex flex-col lg:gap-[25px] items-center font-redRose lg:w-[925px] lg:h-[167px] w-[330px] h-[110px] gap-[10px] mt-[10px] lg:mt-[0px] md:w-[500px] md:h-[180px] md:gap-[20px] description-inner-container">
            <div className="text-[20px] leading-[25px] text-center tracking-[2px] font-bold lg:tracking-[10px] md:text-[25px] lg:flex md:leading-[35px] md:tracking-[5px] lg:text-[38px] lg:leading-[47.46px] space-x-[10px] description-caption">
              <h1>{theme}</h1>
              <h1>{caption}</h1>
            </div>
            <h2 className="w-[330px] h-[63px] text-[16px] leading-[14.5px] md:text-[22px] lg:text-[24px] lg:w-[816px] lg:h-[70px] font-normal lg:leading-[34.97px] tracking-[2px] text-center md:w-[500px] md:h-[40px] md:text-[23px] md:leading-[25px] md:tracking-[2px] description-theme">
                {description}
            </h2>
          </div>
        </div>
    )
}

export default AntecedentsDescriptionContainer;