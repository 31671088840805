import engagementCommitteImage from '../assets/images/engagementCommittee.webp';
import technicalCommitteCardImage from '../assets/images/technicalCommitteCardImage.webp';
import websiteDevelopmentCardImage from '../assets/images/web_dev_committee.webp';
import FoodCommitteeCardImage from '../assets/images/FoodCommitteeImage.webp';
import logisticsCommitteCardImage from '../assets/images/logisticsCommitteCardImage.webp';
import CreativeCommitteCard from '../assets/images/creativeCommittee.webp';

// food_goodies
import Mohamed from "../assets/images/CommitteeImages/foods_goodies/Mohamed Ansari.webp"
import Foujoul from "../assets/images/CommitteeImages/foods_goodies/Foujul Hithaya.webp"
import Gomathi from "../assets/images/CommitteeImages/foods_goodies/Gomathi.webp"
import Sriram from "../assets/images/CommitteeImages/foods_goodies/sriram.webp"
import Narmadha from "../assets/images/CommitteeImages/foods_goodies/narmadha.webp"
import Dineshkumar from "../assets/images/CommitteeImages/foods_goodies/Dineshkumar.webp"
import Sasidharan from "../assets/images/CommitteeImages/foods_goodies/sasidharan.webp"
import Prabhu from "../assets/images/CommitteeImages/foods_goodies/Prabhu.webp"
import Kuberavarman from "../assets/images/CommitteeImages/foods_goodies/Kuberan.webp"
import Aswin from "../assets/images/CommitteeImages/foods_goodies/Aswin.webp"
import Thanushree from "../assets/images/CommitteeImages/foods_goodies/Thanu.webp"
import Shakthivel from "../assets/images/CommitteeImages/foods_goodies/shakthivel.webp"

// Website Dev images

import Mukesh from "../assets/images/CommitteeImages/web_dev/Mukesh.webp";
import vishnu from "../assets/images/CommitteeImages/web_dev/vishnu.webp";
import shuvethram from "../assets/images/CommitteeImages/web_dev/shuvethram.webp";
import Rakesh from "../assets/images/CommitteeImages/web_dev/Rakesh.webp";
import Srinivasan from "../assets/images/CommitteeImages/web_dev/Srinivasan.webp"
import surmani from "../assets/images/CommitteeImages/web_dev/surmani.webp"
import chandu from "../assets/images/CommitteeImages/web_dev/chandu.webp"
import sisvanth from "../assets/images/CommitteeImages/web_dev/Sisvanth.webp"
import karthick from "../assets/images/CommitteeImages/web_dev/karthickDharshan.jepg.webp"
import Nachiappan from "../assets/images/CommitteeImages/web_dev/Nachiappan.webp"
import somkumar from "../assets/images/CommitteeImages/web_dev/somkumar.webp"
import arun from "../assets/images/CommitteeImages/web_dev/arun_kumar.webp"
import pavithra_kumar from "../assets/images/CommitteeImages/web_dev/pavithra_kumar.webp"
import Kirubakar from "../assets/images/CommitteeImages/web_dev/kirubakar.webp"
import abdul from "../assets/images/CommitteeImages/web_dev/abdul.webp"

//technical committee images

import Giridhar from "../assets/images/CommitteeImages/technicalCommittee/Giridhar.webp";
import Gokul from "../assets/images/CommitteeImages/technicalCommittee/Gokul.webp";
import GundlaSreekanth from "../assets/images/CommitteeImages/technicalCommittee/Gundla Sreekanth.webp";
import Hema from "../assets/images/CommitteeImages/technicalCommittee/Hema Photo.webp";
import KishoreKumar from "../assets/images/CommitteeImages/technicalCommittee/Kishorkumar Dhanabose.webp";
import Praneeth from "../assets/images/CommitteeImages/technicalCommittee/Praneeth.webp";
import Renu from "../assets/images/CommitteeImages/technicalCommittee/Renu-Picture.webp";
import SaiSachin from "../assets/images/CommitteeImages/technicalCommittee/sai sachin.webp";
import SaiSudha from "../assets/images/CommitteeImages/technicalCommittee/Sai Sudha.webp";
import Varshitha from "../assets/images/CommitteeImages/technicalCommittee/Varshitha1.webp";
import GnanaSarathy from "../assets/images/CommitteeImages/technicalCommittee/GnanaSarathy.webp";
import Vaishnavi from "../assets/images/CommitteeImages/technicalCommittee/Vaishnavi.webp";

//engagement committee images

import AlloJeswin from "../assets/images/CommitteeImages/EngagementCommittee/Allo Jeswin christopher.webp";
import AnandKumar from "../assets/images/CommitteeImages/EngagementCommittee/AnandKumar.webp";
import Ashok from "../assets/images/CommitteeImages/EngagementCommittee/Ashok Natarajan .webp"
import Barath from "../assets/images/CommitteeImages/EngagementCommittee/Bharath_mikkilenni.webp";
import Kiruthika from "../assets/images/CommitteeImages/EngagementCommittee/Kiruthika Thiyagarajan.webp";
import maamathi from "../assets/images/CommitteeImages/EngagementCommittee/maamathi.webp";
import MurappuHarishReddy from "../assets/images/CommitteeImages/EngagementCommittee/Murapu Harish Reddy.webp";
import Pavithra from "../assets/images/CommitteeImages/EngagementCommittee/pavithra.webp";
import Praveen from "../assets/images/CommitteeImages/EngagementCommittee/praveen_gopa.webp";
import SanthoshKumar from "../assets/images/CommitteeImages/EngagementCommittee/Santhosh Kumar AG.webp";
import Sarpabushan from "../assets/images/CommitteeImages/EngagementCommittee/Sarpabhushan Aradhya.webp";
import muthuLakshmi from "../assets/images/CommitteeImages/EngagementCommittee/Muthulakshmi.webp";
import Ramprassana from "../assets/images/CommitteeImages/EngagementCommittee/Ramprassana.webp";
import Gurunathan from "../assets/images/CommitteeImages/EngagementCommittee/GURUNATHAN.webp";
import Tejesh from "../assets/images/CommitteeImages/EngagementCommittee/Venkat.webp";

// logistics Committee
import uday from "../assets/images/CommitteeImages/logistics_committee/uday.jpeg";
import Anjani from "../assets/images/CommitteeImages/logistics_committee/anjani.webp";
import kumeresh from "../assets/images/CommitteeImages/logistics_committee/kumeresh.webp";
import Sowdesh from "../assets/images/CommitteeImages/logistics_committee/sowdesh.webp";
import Hariharan from "../assets/images/CommitteeImages/logistics_committee/hariharan.webp";
import PraveenR from "../assets/images/CommitteeImages/logistics_committee/PraveenR.webp";
import Premraj from "../assets/images/CommitteeImages/logistics_committee/prem.webp";
import Pravalika from "../assets/images/CommitteeImages/logistics_committee/pravalika.webp";
import Salmanalli from "../assets/images/CommitteeImages/logistics_committee/salman_khan.webp";
import Anantharaman from "../assets/images/CommitteeImages/logistics_committee/Ananntharaman.webp";
import Praveen_Kumar from "../assets/images/CommitteeImages/logistics_committee/Praveen_kumar.webp";

// Creative Committee

import Akshaya from "../assets/images/CommitteeImages/Creative-Commitee/akshaya.webp";
import sharvesh from "../assets/images/CommitteeImages/Creative-Commitee/sharvesh.webp";
import guru from "../assets/images/CommitteeImages/Creative-Commitee/guru.webp";
import Ridesh from "../assets/images/CommitteeImages/Creative-Commitee/ridesh_r.webp";
import joel from "../assets/images/CommitteeImages/Creative-Commitee/joel.webp";
import Mathiarasi from "../assets/images/CommitteeImages/Creative-Commitee/mathiarasi.webp";
import Sathish from "../assets/images/CommitteeImages/Creative-Commitee/sathish.webp";
import alagu from "../assets/images/CommitteeImages/Creative-Commitee/alagu.webp";
const committeeCardInfo = [
    {
        imageUrl: engagementCommitteImage,
        displayText: "ENGAGEMENT COMMITTEE",
        membersData: [
            {
                imageUrl: MurappuHarishReddy,
                memberName: "Harish Reddy",
            },
            {

                imageUrl: SanthoshKumar,
                memberName: "Santhosh Kumar",
            },
            {
                imageUrl: AnandKumar,
                memberName: "Anandkumar Jha",
            },
            {
                imageUrl: Gurunathan,
                memberName: "Gurunathan Balakrishnan",
            },
            {
                imageUrl: maamathi,
                memberName: "Maamathi",
            },

            {
                imageUrl: Pavithra,
                memberName: "Pavithra",
            }, {
                imageUrl: AlloJeswin,
                memberName: "Allo Jeswin Christopher",
            },
            {
                imageUrl: Barath,
                memberName: "Bharath M",
            },
            {
                imageUrl: Ramprassana,
                memberName: "Ramprassana T",
            },
            {
                imageUrl: Kiruthika,
                memberName: "Kiruthika Thiyagarajan",
            },
            {
                imageUrl: Ashok,
                memberName: "Ashok N",
            },
            {
                imageUrl: muthuLakshmi,
                memberName: "Muthulakshmi",
            },

            {
                imageUrl: Praveen,
                memberName: "Praveen Gopal",
            }, 
            {
                imageUrl: Sarpabushan,
                memberName: "Sarpabhushan Aradhya",
            },
            {
                imageUrl: Tejesh,
                memberName: "Venkata Tejesh Kanula",
            },


        ]
    },
    {
        imageUrl: technicalCommitteCardImage,
        displayText: "TECHNICAL COMMITTEE",
        membersData: [
            {
                imageUrl: Hema,
                memberName: "Hemamalini Kamalakannan",
            },
            {
                imageUrl: Renu,
                memberName: "Renu Rajaraman",
            },
            {
                imageUrl:SaiSachin,
                memberName: "Sai Sachin",
            },
            {
                imageUrl: Praneeth,
                memberName: "Praneeth",
            },
            {
                imageUrl: Vaishnavi,
                memberName: "Shree Vaishnavi",
            },

            {
                imageUrl: SaiSudha,
                memberName: "Sai Sudha R",
            }, {
                imageUrl: Gokul,
                memberName: "Gokul Kannan",
            },
            {
                imageUrl: Varshitha,
                memberName: "Varshitha G",
            },
            {
                imageUrl: GnanaSarathy,
                memberName: "Gnana Sarathy A",
            },
            {
                imageUrl: GundlaSreekanth,
                memberName: "Gundla Sreekanth",
            },
            {
                imageUrl: Giridhar,
                memberName: "Giridhar Alwar",
            },
            {
                imageUrl: KishoreKumar,
                memberName: "Kishor kumar",
            }
        ]
    },
    {
        imageUrl: websiteDevelopmentCardImage,
        displayText: "WEBSITE DEVELOPMENT",
        membersData: [
            {
                imageUrl: shuvethram,
                memberName: "Shuvethram Ayyasamy Ragupathy",
            },
            {
                imageUrl: Mukesh,
                memberName: "MukeshKhanna",
            },
            {
                imageUrl: abdul,
                memberName: "Abdul Adhil Kajamaideen",
            },
            {
                imageUrl: Rakesh,
                memberName: "Rakhesh Bowtham",
            },
            {
                imageUrl: chandu,
                memberName: "Chandra Sekhar Bodasingu",
            },
            {
                imageUrl: somkumar,
                memberName: "Somukumar Ekambaram",
            },
            {
                imageUrl: arun,
                memberName: "Arun Ponnusamy",
            },

            {
                imageUrl: surmani,
                memberName: "Surmani",
            }, {
                imageUrl: Srinivasan,
                memberName: "Srinivasan",
            },
            {
                imageUrl: Nachiappan,
                memberName: "Nachiappan",
            },
            {
                imageUrl: vishnu,
                memberName: "Vishnuraj",
            },
            {
                imageUrl: Kirubakar,
                memberName: "Kirubakar Thangasamy",
            },
            {
                imageUrl: AlloJeswin,
                memberName: "Allo Jeswin Christopher",
            },
            {
                imageUrl: pavithra_kumar,
                memberName: "Pavithra",
            },

            {
                imageUrl: karthick,
                memberName: "Karthick Dharshan",
            },
            {
                imageUrl: sisvanth,
                memberName: "Sisvanth",
            },

        ]
    },
    {
        imageUrl: CreativeCommitteCard,
        displayText: "CREATIVE COMMITTEE",
        membersData: [
            {
                imageUrl: guru,
                memberName: "Gurudharshan Nehru",
            },
            {
                imageUrl: Mathiarasi,
                memberName: "Mathiarasi",
            },
            {
                imageUrl: Akshaya,
                memberName: "Akshaya",
            },

            {
                imageUrl: Ridesh,
                memberName: "Ridesh Raj",
            }, {
                imageUrl: alagu,
                memberName: "Alagu",
            },
            {
                imageUrl: sharvesh,
                memberName: "Sharvesh",
            },
            {
                imageUrl: joel,
                memberName: "Joel Moses",
            },
            {
                imageUrl: Sathish,
                memberName: "Sathish",
            },
            {
                imageUrl: pavithra_kumar,
                memberName: "Pavithra",
            },

        ]
    },
    {
        imageUrl: FoodCommitteeCardImage,
        displayText: "FOOD & GOODIES",
        membersData: [
            {
                imageUrl: Thanushree,
                memberName: "Thanushree Srinivasan",
            },
            {
                imageUrl: Mohamed,
                memberName: "Mohamed Ansari",
            },
            {
                imageUrl: Foujoul,
                memberName: "Foujoul",
            },
            {
                imageUrl: Gomathi,
                memberName: "Gomathi Madhavan",
            },
            {
                imageUrl: Shakthivel,
                memberName: "Sakthivel",
            },
            {
                imageUrl: Sriram,
                memberName: "Sriram Manikandan",
            },

            {
                imageUrl: Narmadha,
                memberName: "Narmadha",
            },
            {
                imageUrl: Dineshkumar,
                memberName: "Dineshkumar Ravi",
            },
            {
                imageUrl: Sasidharan,
                memberName: "Sasidharan Soundararajan",
            },
            {
                imageUrl: Prabhu,
                memberName: "Prabhu Sivakumara",
            },
            {
                imageUrl: Kuberavarman,
                memberName: "Kuberavarman",
            },
            {
                imageUrl:Aswin,
                memberName: "Aswin",
            }
        ]
        ,
    },
    {
        imageUrl: logisticsCommitteCardImage,
        displayText: "LOGISTICS",
        membersData: [
            {
                imageUrl:  uday ,
                memberName: "Mandadi Udaykumar Reddy",
            },
            {
                imageUrl:  Anjani ,
                memberName: "Anjani Guddakar",
            },
            {
                imageUrl:  Sowdesh ,
                memberName: "Mohana Sowdesh",
            },
            {
                imageUrl:  Hariharan ,
                memberName: "Hariharan Shakthivel",
            },
            {
                imageUrl:  kumeresh ,
                memberName: "Kumaresh",
            },
            {
                imageUrl:   PraveenR,
                memberName: "Praveen R",
            },
            {
                imageUrl:  Premraj ,
                memberName: "Premraj S",
            },
            {
                imageUrl:   Pravalika,
                memberName: "Pravalika Jonnalagadda",
            },
            {
                imageUrl:  Salmanalli ,
                memberName: "Salmanalli Khan Kadiri",
            },
            {
                imageUrl: Anantharaman  ,
                memberName: "Anantharaman",
            },
            {
                imageUrl:   Praveen_Kumar,
                memberName: "Praveen Kumar",
            },
        ]
    }
]

export default committeeCardInfo;