import React from 'react';
import './styles.scss';

const HomepageButton = ({ name, onClick, logoPosition, logo }) => {
  return (
    <div className='border-mask-home gradient-border-mask-home' onClick={onClick}>
      <button className='font-space font-light text-white  rounded-2xl text-sm  lg:text-xl flex items-center justify-between py-2 px-4'>
        {logoPosition === 'left' && logo && <span className='logo w-[16px]'>{logo}</span>}
        <span className='text'>{name}</span>
        {logoPosition === 'right' && logo && <span className='logo'>{logo}</span>}
      </button>
    </div>
  );
};

export default HomepageButton;