// Witches and Wizards
import sarathy from "../assets/images/ParticipantsImages/GnanaSarathy.webp";
import mukesh from "../assets/images/ParticipantsImages/mukesh.webp";
import heamPriyaData from "../assets/images/ParticipantsImages/hemaPriya.webp";
import Akshaya from "../assets/images/ParticipantsImages/Akshaya.webp";
import christopher from "../assets/images/ParticipantsImages/christopher.webp";
// Phoenix
import Divya from "../assets/images/ParticipantsImages/Divya.webp";
import james from "../assets/images/ParticipantsImages/james.webp";
import naveen from "../assets/images/ParticipantsImages/Naveen Karthick.webp";
// Team Smart Farm
import avinash from "../assets/images/ParticipantsImages/Avinash.webp";
import mohan_raj from "../assets/images/ParticipantsImages/Mohan_Raj.webp";
import rajiv from "../assets/images/ParticipantsImages/ravi.webp";

import ashok from "../assets/images/ParticipantsImages/ashok.webp";
import Vikash from "../assets/images/ParticipantsImages/vikash.webp";
import person1 from "../assets/images/ParticipantsImages/person1.webp";
import person2 from "../assets/images/ParticipantsImages/person2.webp";
import Sudharsan from "../assets/images/ParticipantsImages/Sudharsan.webp";

// Design Fusionists
import Mathiarasi from "../assets/images/CommitteeImages/Creative-Commitee/mathiarasi.webp";
import NavvenN from "../assets/images/ParticipantsImages/Naveen.webp";
import abdul from "../assets/images/CommitteeImages/web_dev/abdul.webp"
import hema from "../assets/images/ParticipantsImages/Hema.webp";
import linthesh from "../assets/images/ParticipantsImages/linthesh.webp"
// AEM Checkmate
import Ashwin from "../assets/images/ParticipantsImages/ashwin.webp"
import Praveen_Gopal from "../assets/images/CommitteeImages/EngagementCommittee/praveen_gopa.webp";
import Thanushree from "../assets/images/CommitteeImages/foods_goodies/Thanu.webp"
// outofthebox
import Rakesh from "../assets/images/CommitteeImages/web_dev/Rakesh.webp";
import Sathish from "../assets/images/CommitteeImages/Creative-Commitee/sathish.webp";
import joel from "../assets/images/CommitteeImages/Creative-Commitee/joel.webp";
import gaurav from "../assets/images/ParticipantsImages/gavrav.webp";
import naveensnow from "../assets/images/ParticipantsImages/navvenSnow.webp"
// Glam strutters
import pooja from  "../assets/images/ParticipantsImages/PoojaManikandan.webp";
import aravindh from  "../assets/images/ParticipantsImages/Aravind.webp";
import dinesh from  "../assets/images/ParticipantsImages/Dinesh.webp";
import rithish from  "../assets/images/ParticipantsImages/Rithish.webp";
import shakthi from  "../assets/images/ParticipantsImages/Shakthivel.webp";
// GCP Unicorns
import rajalakshmi from "../assets/images/ParticipantsImages/Rajalakshmi.webp"
import sepuri from "../assets/images/ParticipantsImages/sepuri.webp"
import sharmila from "../assets/images/ParticipantsImages/sharmila.webp"
import suraj from "../assets/images/ParticipantsImages/suraj.webp"
import Chris from "../assets/images/ParticipantsImages/Chris_Clouse.webp"

// commerce commets
import sam from "../assets/images/ParticipantsImages/SAM.webp";
import Jerry from "../assets/images/ParticipantsImages/jerry.webp";
import Selvi from "../assets/images/ParticipantsImages/Selvi.webp";
import Niranjana from "../assets/images/ParticipantsImages/niranjana.webp";
// SNOW SQUAD
import Bosco from "../assets/images/ParticipantsImages/Bosco.webp";
import Syed from "../assets/images/ParticipantsImages/Syed.webp";
import Maria from "../assets/images/ParticipantsImages/Maria.webp";
import Priyadharshini from "../assets/images/ParticipantsImages/Priyadharshini.webp";
import Namie from "../assets/images/ParticipantsImages/Namie.webp";


// VR Selects
import abishek from "../assets/images/ParticipantsImages/abishek.webp";
import adheesh from "../assets/images/ParticipantsImages/adheesh.webp";
import  chandu from "../assets/images/ParticipantsImages/chandrasekhar.webp";
import kavinPrasath from"../assets/images/ParticipantsImages/Kavinprasath.webp";
import prakash from "../assets/images/ParticipantsImages/prakash.webp";
// OmniQSuite
import Ridesh from "../assets/images/CommitteeImages/Creative-Commitee/ridesh_r.webp";
import Hariboobaalan from"../assets/images/ParticipantsImages/Hariboobalan.webp";
import Naren from"../assets/images/ParticipantsImages/Naren.webp";
import saikrishna from"../assets/images/ParticipantsImages/SaiKrishna.webp";
import Kamalesh from"../assets/images/ParticipantsImages/Kamalesh.webp";
// Data Packers and Movers
import bharath from "../assets/images/ParticipantsImages/bharath.webp";
import shandhya from "../assets/images/ParticipantsImages/shandhya.webp";
import Ramprassana from "../assets/images/CommitteeImages/EngagementCommittee/Ramprassana.webp";
import PraveenR from "../assets/images/CommitteeImages/logistics_committee/PraveenR.webp";
import varshinee from "../assets/images/ParticipantsImages/varshinee.webp";

// Cloud Builders 2.0
import sridhar from "../assets/images/ParticipantsImages/sridharRamasamy.webp";
import nadhya from "../assets/images/ParticipantsImages/Madhu_NandyaNaik.webp";
import sharankumar from "../assets/images/ParticipantsImages/SharanKumar.webp";

// CREWsaders
import kavya from "../assets/images/ParticipantsImages/kavvya.webp";
import ayush from "../assets/images/ParticipantsImages/Ayush.webp"
import sriram from "../assets/images/ParticipantsImages/sriram.webp";
import kavinKumar from "../assets/images/ParticipantsImages/kavinKumar.webp";
import alagu from "../assets/images/CommitteeImages/Creative-Commitee/alagu.webp";
// wolf pack
import durga from "../assets/images/ParticipantsImages/durga.webp"; 
import lakshmanan from "../assets/images/ParticipantsImages/lakshmanan.webp"; 
import varun from "../assets/images/ParticipantsImages/Varun Subramaniam 1.webp"; 
import abhishek_WP from "../assets/images/ParticipantsImages/abhishek_WP.webp";
// Glitch Hunters
import pavithra from "../assets/images/ParticipantsImages/Pavithra.webp";
import Ravali from "../assets/images/ParticipantsImages/Ravali.webp";
import suganya from "../assets/images/ParticipantsImages/Suganya.webp";
import Deepana from "../assets/images/ParticipantsImages/Deepana.webp";
import KarthikeyanJagadeesan from "../assets/images/ParticipantsImages/KarthikeyanJegadeesan.webp";


//matrix renovators
import aravinthan from "../assets/images/ParticipantsImages/aravinthan.webp";
import ranjith from "../assets/images/ParticipantsImages/ranjith.webp";
import harish from "../assets/images/ParticipantsImages/Harish raja.webp";
import naveenRaja from "../assets/images/ParticipantsImages/naveen raja.webp";
import Caeser from "../assets/images/ParticipantsImages/caesar.webp";

// Ticket Masters
import jenisha from "../assets/images/ParticipantsImages/Jenisha_Roshan.webp";
import nandeesh  from "../assets/images/ParticipantsImages/Nandeesh_V.webp";
import sowdesh from "../assets/images/ParticipantsImages/sowdesh.webp";
import Karthikeyan from "../assets/images/ParticipantsImages/Karthikeyan.webp";

//Techfit testers
import francis from "../assets/images/ParticipantsImages/Francis.webp";
import aadithya from "../assets/images/ParticipantsImages/Aadithya.webp";
import pranay from "../assets/images/ParticipantsImages/Pranay.webp";
import ramPraneeth from "../assets/images/ParticipantsImages/Raam Praneeth.webp";
import ankit from "../assets/images/ParticipantsImages/ankit1.webp";

export class APP_CONSTANTS {
  static POSTER_PAGE = {
    register_btn_label: 'Register now',
    register_btn_url: 'https://forms.office.com/r/4YAvQ25Jff',
    prev_year_label: 'CNG\'23 Website',
    prev_year_url: 'https://www.codesngears.com/'
  }

  static NAVBAR_COMPONENTS = {
    home_label: 'HOME',
    event_label: 'EVENTS',
    participants_label: 'TEAM STATUS',
    antcendents_label: 'ANTECEDENTS',
    juries_label: 'JURIES',
    committees_label: 'COMMITTEES',
    winners_label:'WINNERS',
    home_key: '',
    event_key: 'events',
    participants_key: 'statusPage',
    antcendents_key: 'antecedents',
    juries_key: 'juries',
    committees_key: 'committeePage',
    winners_key:'winners',
    cdw_website: 'https://www.cdw.com'
  }
}


export const TEAM_DETAILS = [
  {
    "title": "Witches and Wizards",//Selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Mukesh Ravi",
      image:mukesh
    } ,
    {
      name:"Akshaya Dhanraj",
      image:Akshaya
    },
    {
      name:"Gnana Sarathy A",
      image:sarathy
    },
    {
      name:"Hemapriya Nagarajan",
      image:heamPriyaData
    },
    {
      name:"Christopher Marcolis",
      image:christopher
    },
   ]
  },
  {
    "title": "Phoenix",//selected
    "status": true,
    "type":"thinkathon",
    "members": [{
      name:"Naveen Karthik",
      image:naveen
    },
    {
      name:"James Prince Arockiasamy",
      image:james
    },
    {
      name:"Divya Gopalakrishnan ",
      image:Divya
    },]
  },
  {
    "title": "Team Smart Farm",//selected
    "status": true,
    "type":"thinkathon",
    "members": [{
      name:"Avinash Bembavarapu",
      image:avinash
    },
    {
      name:"Mohanraj Janarthanan",
      image:mohan_raj
    },
    {
      name:"Rajiv Kumar",
      image:rajiv
    },]
  },
  {
    "title": "Design Fusionists",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Mathiarasi Nagaraj",
      image:Mathiarasi
    },
    {
      name:"Naveen kumar",
      image:NavvenN
    },
    {
      name:"Abdul Adhil Kajamaideen",
      image:abdul
    },
    {
      name:"Hemavathy Ravichandran",
      image:hema
    },
    {
      name:"Lintheshwar Sakthivel",
      image:linthesh
    }],
  },
  {
    "title": "GCP Unicorns",//selected
    "status": true,
    "type":"codeathon",
    "members": [
    {
      name:"Shaik Sharmila",
      image:sharmila
    },
    {
      name:"Suraj Kumar Shettigar",
      image:suraj
    },
    {
      name:"Sepuri Srinivasulu",
      image:sepuri
    },{
      name:"Rajalakshmi Ganesh Babu",
      image:rajalakshmi
    },
    {
      name:"Chris Clouse",
      image:Chris
    }],
  },
  {
    "title": "TechFit Testers",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Francis Bell Ajay",
      image:francis
    },
    {
      name:"Pranay Lakshmanan",
      image:pranay
    },
    {
      name:"Aadithya Sundararaman",
      image:aadithya
    },{
      name:"Raam Praneeth",
      image:ramPraneeth
    }
    ,{
      name:"Ankit Dixit",
      image:ankit
    },]
  },
  {
    "title": "HealthyPoint",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Rahimulah Rahimi",
      image:person2
    },
    {
      name:"Sudharsan Sekar",
      image:Sudharsan
    },{
      name:"Vikash",
      image:Vikash
    }
    ,{
      name:"Ashok",
      image:ashok
    },
    {
      name:"Guduri Teja",
      image:person1
    },],
  },
  {
    "title": "Ticket Masters",//selected
    "status": true,
    "type":"codeathon",
    "members": [
    {
      name:"Jenisha Roshan",
      image:jenisha
    },{
      name:"Nandeesh",
      image:nandeesh
    }
    ,{
      name:"Mohana Sowdesh",
      image:sowdesh
    },
    {
      name:"Karthikeyan Chokalingam",
      image:Karthikeyan
    },],
  },
  {
    "title": "SNOW Squad",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Bosco Sahayaraj",
      image:Bosco
    },
    {
      name:"Syed Multhazim Javathu Ahamed",
      image:Syed
    },{
      name:"Maria Shyamala Abiraham",
      image:Maria
    }
    ,{
      name:"Priyadharshini Karupasamy",
      image:Priyadharshini
    },
    {
      name:"Namie Kanteti",
      image:Namie
    },],
  },
  {
    "title": "Commerce Comets",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Jerry Oswin Princeton Kumar",
      image:Jerry
    },
    {
      name:"Karuppana Samy Periasamy",
      image:sam
    },{
      name:"Niranjana Lingeswaran",
      image:Niranjana
    }
    ,{
      name:"Arunjunai Selvi Murugan",
      image:Selvi
    }],
  },
  {
    "title": "Glam strutters",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Rithish",
      image:rithish
    },
    {
      name:"Aravindha",
      image:aravindh
    },{
      name:"Dinesh",
      image:dinesh
    }
    ,{
      name:"Pooja Manikandan",
      image:pooja
    },
    {
      name:"Sakthivel Kannan",
      image:shakthi
    },],
  },
  {
    "title": "Data Packers and Movers",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Varshinee Venkatesan",
      image:varshinee
    },
    {
      name:"Sandhya Govindasamy",
      image:shandhya
    },{
      name:"Praveen Rathinavel",
      image:PraveenR
    }
    ,{
      name:"Ramprassana Thirumalaisamy",
      image:Ramprassana
    },
    {
      name:"Bharath Mikkilineni",
      image:bharath
    },],
  },
  {
    "title": "Wolf Pack",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Varun Subramaniam",
      image:varun
    },
    {
      name:"Abhishek Jayaraman",
      image:abhishek_WP
    },{
      name:"Durga Bogada",
      image:durga
    }
    ,{
      name:"Laxmanapandi Baskaran",
      image:lakshmanan
    },],
  },
  {
    "title": "V R Select - (Virtual Reality Select)",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Kavinprasath Mathaiyan",
      image:kavinPrasath
    },
    {
      name:"Prakash Rameshkumar",
      image:prakash
    },
    {
      name:"Adheesh Arumugam",
      image:adheesh
    },
    {
      name:"Abishek Duraisamy",
      image:abishek
    },
    {
      name:"Chandra Sekar Bodasingu",
      image:chandu
    },
  ],
  },
  {
    "title": "Matrix Renovators",//selected
    "status": true,
    "type":"codeathon",
      "members": [{
        name:"Aravindhan Asaithambi",
        image:aravinthan
      },
      {
        name:"Hareesh Raj Ramanathan",
        image:harish
      },
      {
        name:"Naveen Raja Nagarathinam",
        image:naveenRaja
      },
      {
        name:"Ranjith Saravanan",
        image:ranjith
      },
      {
        name:"Caeser Bernardo",
        image:Caeser
      },
    ],
  },
  {
    "title": "AEM Checkmate",//selected
    "status": true,
    "type":"thinkathon",
    "members": [{
      name:"Praveen gopal",
      image:Praveen_Gopal
    },
    {
      name:"Aswin kumar Marx",
      image:Ashwin
    },
    {
      name:"Thanushree Srinivasan",
      image:Thanushree
    },
  ],
  },
  {
    "title": "Cloud Builders 2.0",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Madhu Nandya Naik",
      image:nadhya
    },
    {
      name:"Sridhar Ramasamy",
      image:sridhar
    },
    {
      name:"Sharankumar Madanagopal",
      image:sharankumar
    }
  ],
  },
  {
    "title": "Glitch Hunters",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Suganya Kandasamy",
      image:suganya
    },
    {
      name:"Deepana Shanmugam",
      image:Deepana
    },
    {
      name:"Venkata Sai Lakshmi Ravali Guda",
      image:Ravali
    },
    {
      name:"Pavithra Kumar",
      image:pavithra
    },{
      name:"Karthikeyan Jegadeesan",
      image:KarthikeyanJagadeesan
    }
  ],
  },
  {
    "title": "Out Of Box",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Gaurav Rai",
      image:gaurav
    },
    {
      name:"Naveen Gowthaman",
      image:naveensnow
    },
    {
      name:"Joel Moses",
      image:joel
    },
    {
      name:"Sathish Selvan",
      image:Sathish
    },{
      name:"Rakhesh Bowtham",
      image:Rakesh
    }
  ],
  },
  {
    "title": "OmniQSuite",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"Narendhiran Neelakandan",
      image:Naren
    },
    {
      name:"Ridesh Raj",
      image:Ridesh
    },
    {
      name:"Hariboobaalan Palanisamy",
      image:Hariboobaalan
    },
    {
      name:"Kamalesh",
      image:Kamalesh
    },{
      name:"Sai Krishna Babu",
      image:saikrishna
    }
  ],
  },
  {
    "title": "CREWsaders",//selected
    "status": true,
    "type":"codeathon",
    "members": [{
      name:"KavinKumar Baskar",
      image:kavinKumar
    },
    {
      name:"Alagu Bala Ganapathy",
      image:alagu
    },
    {
      name:"Ayush Saxena",
      image:ayush
    },
    {
      name:"Kavyapriya Govindarajan",
      image:kavya
    },{
      name:"Sriram Manikandan",
      image:sriram
    },
    ]
  },
]

export const COUNTER_COMPONENT_DATA = [
  {
    "attribute": "CO-WORKERS", 
    "limit": 96
  },
  {
    "attribute": "TEAMS",
    "limit": 21
  },
  {
    "attribute": "CODEATHON",
    "limit": 18
  },
  {
    "attribute": "THINKATHON",
    "limit": 3
  }
]

export const SIDE_NAVBAR_DATA = [
  {
    label: "HOME",
    key: "home",
    imageUrl: "home.svg",
    urlPath:"/"
  },
  {
    label: "EVENTS",
    key: "events",
    imageUrl: "events.svg",
    urlPath:"/events"
  },
  {
     label: "PARTICIPANTS",
     key: "participants",
     imageUrl: "participants.svg",
     urlPath:"/statusPage"
  },
  {
    label: "ANTECEDENTS",
    key: "antecedents",
    imageUrl: "antecedents.svg",
    urlPath:"/antecedents"
  },
  {
    label: "JURIES",
    key: "juries",
    imageUrl: "jury.svg",
    urlPath:"/juries"
  },
  {
    label: "COMMITTEES",
    key: "committees",
    imageUrl: "committes.svg",
    urlPath:"/committeePage"
  },
  {
      label: "WINNERS",
      key: "winners",
      imageUrl: "winners.svg",
      urlPath:"/winners"
  },
  //{
  //   label: "EVENT WINNERS",
  //   key: "eventWinners",
  //   imageUrl: "event_winners.svg",
  //   urlPath:"/"
  // }
]
