import './styles.scss';
import React from 'react';
import user from "../../assets/images/ParticipantsImages/userPhoto.webp";
const SingleMember = ({name , image}) => {
    return <>
    <div className='member  flex flex-col items-center gap-2'>
                <div className='member-image-container lg:w-  md:h-[120px] md:w-[120px] rounded-full md:rounded-3xl overflow-hidden' >
                    <img src={image != "" ? image: user  } className='w-full h-full object-cover' />
                </div>
                <p className='member-name font-redRose text-white text-center'>
                   {name}
                </p>
            </div>
    </>
}

const SingleTeam = ({title , members ,count}) => {
  return (
    <>
            <div className={`single-team w-full flex ${count % 2 ==0 ? "" : "flex-row-reverse" } `}>
            <div className="team-members-list h-full flex  flex-col items-center gap-4  w-full md:w-[55%] rounded-[40px] lg:rounded-2xl p-4 xl:p-10">
            <div className='block team-name-box  min-w-1/2  md:hidden mx-auto  px-4 py-3'>
                    <p className='team-name text-center text-lgtext-center font-medium text-white font-redRose uppercase'>
                        {title}
                    </p>
            </div>
            <div className='team-members-inner-list w-full  flex  flex-wrap justify-center gap-y-8'>
                {
                    members.map( el => <SingleMember {...el}/>)
                }
            </div>
            </div>
            <div className="hidden md:flex team-name-container flex items-center justify-center w-full md:w-[50%] ">
                <div className='team-name-box px-8 py-4'>
                    <p className='team-name text-center text-2xl  text-white font-redRose uppercase'>
                    {title}
                    </p>
                </div>
                
            </div>
        </div>
    </>
  )
}

export default SingleTeam
