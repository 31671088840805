import React, { useState } from "react";
import "./styles.scss";
import Navbar from "../../components/Navbar";
import SideNavbar from "../../components/SideNavbar";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import NamAhuja from "../../assets/images/juriesImages/Nam Ahuja.webp";
import EricSzewczyk from "../../assets/images/juriesImages/Erik Szewczyk.webp";
import KevinLangford from "../../assets/images/juriesImages/Kevin Langford.webp";
import KoryThaut from "../../assets/images/juriesImages/kory Thaut.webp";
import BrettNewbury from "../../assets/images/juriesImages/brett newbury.webp";
import AndyJames from "../../assets/images/juriesImages/Andy James.webp";
import AnuragBatra from "../../assets/images/juriesImages/Anurag Batra.webp";
import ChadKnutson from "../../assets/images/juriesImages/chad knutson.webp";
import JideAjomale from "../../assets/images/juriesImages/Jide Ajomale.webp";
import WillDavis from "../../assets/images/juriesImages/Will Davis.webp";
import StephenMorris from "../../assets/images/juriesImages/stephenMorris.webp"
import MikeGutknecht from "../../assets/images/juriesImages/mike.webp";
import { EffectCoverflow, Autoplay } from "swiper/modules";
import HomepageNavbar from "../../components/HomepageNavbar";
// import { EffectCoverflow, Autoplay } from "swiper";

const juries = [
  {
    name: "Stephen Morris",
    designation: "Senior Director Intelligent Platforms",
    subDesignation: "",
    photo: StephenMorris,
  },
  {
    name: "Mike Gutknecht",
    designation: "Director Global Delivery",
    subDesignation: "",
    photo: MikeGutknecht,
  },
  {
    name: "Nam Ahuja",
    designation: "Senior Manager Global Delivery",
    subDesignation: "",
    photo: NamAhuja,
  },
  {
    name: "Eric Szewczyk",
    designation: "Managed Services - VP Delivery",
    subDesignation: "",
    photo: EricSzewczyk,
  },
  {
    name: "Kevin Langford",
    designation: "Managed Services - Senior Manager",
    subDesignation: "",
    photo: KevinLangford,
  },
  {
    name: "Brett Newbury",
    designation: "Managed Services - NOC manager",
    subDesignation: "",
    photo: BrettNewbury,
  },
  {
    name: "Will Davis",
    designation: "Field CTO",
    subDesignation: "",
    photo: WillDavis,
  },
  {
    name: "Kory Thaut",
    designation: "Head of DV Cloud Prof Svcs",
    subDesignation: "",
    photo: KoryThaut,
  },
  {
    name: "Andy James",
    designation: "Director DVS Professional Services",
    subDesignation: "",
    photo: AndyJames,
  },
  {
    name: "Jide Ajomale",
    designation: "Managed SOC",
    subDesignation: "",
    photo: JideAjomale,
  },
  {
    name: "Chad Knutson",
    designation: "Senior Director - Data",
    subDesignation: "",
    photo: ChadKnutson,
  },
  {
    name: "Anurag Batra",
    designation: "Head of Enterprise Architecture",
    subDesignation: "",
    photo: AnuragBatra,
  },
];

export default function JuiresPage() {
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleButtonClick = () => {
    setShowSideNavbar(true);
    setDisplayOverlay(true);
  };

  return (
    <>
      <div className="juries-page-container">
        <HomepageNavbar handleButtonClick={handleButtonClick} />
        <SideNavbar
          showSideNavbar={showSideNavbar}
          displayOverlay={displayOverlay}
          setDisplayOverlay={setDisplayOverlay}
          setShowSideNavbar={setShowSideNavbar}
        />
        <div className="flex justify-center gap-4 tracking-5 juries-top-container">
          <div className="w-8 h-1 sm:w-14 sm:h-2 glow-bar-juries"></div>
          <div className="text-lg sm:text-4xl xl:text-5xl -mr-[5px] juries-text">
            JURIES
          </div>
          <div className="w-8 h-1 sm:w-14 sm:h-2 glow-bar-juries"></div>
        </div>
        <div className="juries-container">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={3}
            slideToClickedSlide={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1.3,
                speed: 900,
                coverflowEffect: {
                  modifier: 3,
                },
              },
              1200: {
                slidesPerView: 3,
                loopAdditionalSlides: 5,
                speed: 1400,
                coverflowEffect: {
                  modifier: 4,
                },
              },
            }}
            modules={[EffectCoverflow, Autoplay]}
            className="swiper-container"
          >
            {juries.map((jury, index) => (
              <SwiperSlide>
                <div className="border-style">
                  <div className="absolute z-10 hidden lg:gap-8 jury-details">
                    <div>
                      <h1 className="">{jury.name}</h1>
                      <div className="glow-bar-juries"></div>
                    </div>
                    <div className="designation">
                      <h2>{jury.designation}</h2>
                      <h2>{jury.subDesignation}</h2>
                    </div>
                  </div>
                  <img src={juries[index].photo} alt="slide_image" className="object-cover card-background" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}
