import { React, useState,useCallback} from "react";
import Navbar from "../../components/Navbar";
import "./styles.scss";
import "../../assets/images/antecedents-bg.webp";
import AntecedentsNameContainer from "../../components/AntecedentsNameContainer";
import AntecedentsDescriptionContainer from "../../components/AntecedentsDescriptionContainer";
import AntecedentsBackgroundContainer from "../../components/AntecedentsBackgroundContainer";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import leftArrow from "../../assets/logos/left-button.svg";
import rightArrow from "../../assets/logos/right-button.svg";
import { ANTECEDENTS_NAME_DETAILS } from "../../constants/antecedents.constants";
import { ANTECEDENTS_DESCRIPTION_DETAILS } from "../../constants/antecedents.constants";
import BUTTON_CONSTANTS from "../../constants/button.constants";
import useScreenSize from "../../core/shared/useScreenSize";
import imageUrl1 from '../../assets/images/AntecedentsPageAssets/image1.webp';
import imageUrl2 from '../../assets/images/AntecedentsPageAssets/image2.webp';
import imageUrl3 from '../../assets/images/AntecedentsPageAssets/image3.webp';
import imageUrl4 from '../../assets/images/AntecedentsPageAssets/image4.webp';
import imageUrl5 from '../../assets/images/AntecedentsPageAssets/image5.webp';
import HomepageNavbar from "../../components/HomepageNavbar";
import SideNavbar from "../../components/SideNavbar";
export default function Antecedents() {
  const antecedentsNameDetails = ANTECEDENTS_NAME_DETAILS;
  const antecedentDescriptionDetails = ANTECEDENTS_DESCRIPTION_DETAILS;
  const [swiper1, setSwiper1] = useState(null);
  const [swiper2, setSwiper2] = useState(null);
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleButtonClick = () => {
    setShowSideNavbar(true);
    setDisplayOverlay(true);
  };
  const screenSize = useScreenSize();
  const width = screenSize.width;
  const handleSlideChange = (swiper) => {
    if (swiper === swiper2 && swiper1) {
      const activeIndex = swiper.activeIndex;
      if (width <= 600) {
        swiper1.slideTo(activeIndex);
      }
    }
  };
  const handleNavigation = (direction) => {
    if (swiper1 && swiper2) {
      const currentIndex = swiper1.activeIndex;
      const totalSlides = swiper1.slides.length;

      let newIndex;

      if (direction === BUTTON_CONSTANTS.swiperCustomButtonConstants.NEXT) {
        newIndex = currentIndex === totalSlides - 1 ? 0 : currentIndex + 1;
      } else if (direction === BUTTON_CONSTANTS.swiperCustomButtonConstants.PREV) {
        newIndex = currentIndex === 0 ? totalSlides - 1 : currentIndex - 1;
      }
      if (width <= 600) {
        swiper2.autoplay = {
          delay: 500000,
          disableOnInteraction: false
        };
        swiper2.slideTo(newIndex);
      }
      else {
        swiper1.slideTo(newIndex);
        swiper2.slideTo(newIndex);
      }
    }
  };
  return (
    <>
      <div className="antecedents-section">
        <AntecedentsBackgroundContainer/>
        <HomepageNavbar handleButtonClick={handleButtonClick} />
        <SideNavbar showSideNavbar={showSideNavbar} displayOverlay={displayOverlay} setDisplayOverlay={setDisplayOverlay} setShowSideNavbar={setShowSideNavbar} />
        <div className="antecedents-inner-container">
          <Swiper
            onSwiper={setSwiper1}
            cssMode={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper1"
          >
            {antecedentsNameDetails.map((item, index) => (
              <SwiperSlide key={index}>
                <AntecedentsNameContainer year={item.year} imageUrl={item.imageUrl}/>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="antecedents-slide-container mt-[20px]">
            <div className ="antecedents-slide">
              <div className="lg:w-[477px] lg:h-[256.67px] w-[371px] h-[200px] md:w-[420px] md:h-[227px]">
                <img src={imageUrl1} alt="antecedents"></img>
              </div>
              <div className ="lg:w-[315.97px] lg:h-[364px] w-[282px] h-[300px] md:w-[300px] md:h-[334px]">
                <img src={imageUrl2} alt="antecedents"></img>
              </div>
              <div className = "lg:w-[315px] lg:h-[380px] flex flex-col gap-y-[10px] w-[282px] h-[310px] md:w-[300px] md:h-[340px]">
                <div className ="lg:w-[314px] lg:h-[189px] w-[282px] h-[150px] md:w-[300px] md:h-[170px]"> 
                  <img src={imageUrl3} alt="antecedents"></img>
                </div>
                <div className="lg:w-[314px] lg:h-[189px] w-[282px] h-[150px] md:w-[300px] md:h-[170px]">
                  <img src={imageUrl4} alt="antecedents"></img>
                </div>
              </div>
              <div className="lg:w-[472px] lg:h-[265px] w-[366px] h-[209px] md:w-[410px] md:h-[229px]">
                <img src={imageUrl5} alt="antecedents"></img>
              </div>
            </div>
            <div className ="antecedents-slide">
              <div className="lg:w-[477px] lg:h-[256.67px] w-[371px] h-[200px] md:w-[420px] md:h-[227px]">
                <img src={imageUrl1} alt="antecedents"></img>
              </div>
              <div className ="lg:w-[315.97px] lg:h-[364px] w-[282px] h-[300px] md:w-[300px] md:h-[334px]">
                <img src={imageUrl2} alt="antecedents"></img>
              </div>
              <div className = "lg:w-[315px] lg:h-[380px] flex flex-col gap-y-[10px] w-[282px] h-[310px] md:w-[300px] md:h-[340px]">
                <div className ="lg:w-[314px] lg:h-[189px] w-[282px] h-[150px] md:w-[300px] md:h-[170px]"> 
                  <img src={imageUrl3} alt="antecedents"></img>
                </div>
                <div className="lg:w-[314px] lg:h-[189px] w-[282px] h-[150px] md:w-[300px] md:h-[170px]">
                  <img src={imageUrl4} alt="antecedents"></img>
                </div>
              </div>
              <div className="lg:w-[472px] lg:h-[265px] w-[366px] h-[209px] md:w-[410px] md:h-[229px]">
                <img src={imageUrl5} alt="antecedents"></img>
              </div>
            </div>
          </div>
          <Swiper
            onSwiper={setSwiper2}
            onSlideChange={() => handleSlideChange(swiper2)}
            cssMode={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper2"
          >
            {antecedentDescriptionDetails.map((item, index) => (
              <SwiperSlide key={index}>
                <AntecedentsDescriptionContainer theme={item.theme} caption={item.caption} description={item.description} imageUrl={item.imageUrl} className="antecedents-description"/>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="custom-navigation">
            <div
              className="custom-prev-button"
              onClick={() => handleNavigation(BUTTON_CONSTANTS.swiperCustomButtonConstants.PREV)}
            >
              <img src={leftArrow} alt="Previous" className="w-8 cursor-pointer sm:w-9 md:w-[50px] md:h-[50px] lg:w-12"/>
            </div>
            <div
              className="custom-next-button"
              onClick={() => handleNavigation(BUTTON_CONSTANTS.swiperCustomButtonConstants.NEXT)}
            >
              <img src={rightArrow} alt="Next" className="w-8 cursor-pointer sm:w-9 md:w-[50px] md:h-[50px] lg:w-12"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}