import React from 'react';
import './styles.scss';
const CommitteeCard = ({imageUrl,displayText,isActive})=>{
    return(
        <div className={` swiper-slide-container lg:w-full h-full  rounded-[10px]`}>
            <div className={`img-container h-full w-full rounded-[10px] overflow-hidden relative  ${isActive ? ` active-image` : ``}`}>
            <div className={`overlay absolute h-full w-full z-10 opacity-30 ${isActive ? `` : `rounded-[10px]`}` }></div>
                <img src = {imageUrl} alt = "FoodCommitteeImage" className={`  w-[350px] h-[100px] lg:h-[150px] lg:w-full  backdrop-opacity-50 object-cover`}></img>
            </div>
            <div className='absolute z-20 top-0 h-full w-full flex justify-center items-center text-center leading-[40px] font-normal text-[#F4F4F4] font-goodTimes text-xs lg:text-xl tracking-[2px] '>{displayText}</div>
        </div>
    )
}

export default CommitteeCard
