import React, { useState } from "react";
import "./styles.scss";
import mainLogoImage from '../../assets/logos/codesNgears.svg';
import EventsPageCard from "../../components/EventsPageCard";
import { EVENT_CONSTANTS } from "../../constants/events.constants";
import HomepageNavbar from "../../components/HomepageNavbar";
import SideNavbar from "../../components/SideNavbar";

export default function EventsPage() {
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleButtonClick = () => {
    setShowSideNavbar(true);
    setDisplayOverlay(true);
  };
  return (
    <div className="events-wrapper">
      <HomepageNavbar handleButtonClick={handleButtonClick} />
      <SideNavbar showSideNavbar={showSideNavbar} displayOverlay={displayOverlay} setDisplayOverlay={setDisplayOverlay} setShowSideNavbar={setShowSideNavbar} />
      <div className="overflow-scroll events-page-container">
        <div className="pt-20 pb-12 lg:pt-0 lg:pb-0 left-container">
          <div className="event-container">
            <div className="glow-bar"></div>
            <div className="event-text ">EVENTS</div>
            <div className="glow-bar"></div>
          </div>
          <div className='logo-image w-3/4 h-[16]'>
            <img className='object-contain w-full h-full' src={mainLogoImage} alt="Codes and Gears - 2024" />
          </div>
          <div className="code-text">
            <p>Code. CREATE. CONQUER.</p>
            <div className="glow-bar"></div>
          </div>
        </div>
        <div className="flex flex-col items-center h-auto px-4 pb-32 md:overflow-scroll right-container lg:justify-between md:gap-14 lg:gap-8 lg:px-16 lg:flex-row lg:overflow-visible ">
          <EventsPageCard {...EVENT_CONSTANTS.EVENTS_CARDS.thinkathon} />
          <EventsPageCard {...EVENT_CONSTANTS.EVENTS_CARDS.codeathon} />
        </div>
      </div>
    </div>
  );
}
