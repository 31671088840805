import calendarIconGreen from "../assets/images/EventPageAssets/calendarIconGreen.svg"
import clockIconGreen from "../assets/images/EventPageAssets/clockIconGreen.svg"
import trophyIconGreen from "../assets/images/EventPageAssets/trophyIconGreen.svg"
import membersIconGreen from "../assets/images/EventPageAssets/membersIconGreen.svg"
import calendarIconBlue from "../assets/images/EventPageAssets/calendarIconBlue.svg"
import clockIconBlue from "../assets/images/EventPageAssets/clockIconBlue.svg"
import trophyIconBlue from "../assets/images/EventPageAssets/trophyIconBlue.svg"
import membersIconBlue from "../assets/images/EventPageAssets/membersIconBlue.svg"

export class EVENT_CONSTANTS {
    static EVENTS_CARDS = {
        thinkathon: {
            type:"thinkathon",
            name:"THINK",
            eventDetails: ["Business focused hackathon.",
                "Bring out business related ideas along with the detailed plan, where CDW can invest and grow.",
                "Business Alignment is mandatory, Working prototype is not.",
                "10 min presentation + 5 min Q & A"
            ],
            date:{
                icon:calendarIconGreen,
                details:"MAY 22 - 23"
            },
            timeSpan:{
                icon:trophyIconGreen,
                details:"25k / Winner"
            },
            prize:{
                icon:clockIconGreen,
                details:"24 hours Event"
            },
            participants:{
                icon:membersIconGreen,
                details:"1 - 3 PARTICIPANTS"
            }
        },
        codeathon: {
            type:"codeathon",
            name:"CODE",
            eventDetails: ["Technology based hackathon",
                "The vast technology space is all yours to explore.",
                "Business Alignment is not mandatory, But a final working prototype is.",
                "15 min demo + 5 min Q & A"
            ],
            date:{
                icon:calendarIconBlue,
                details:"MAY 22 - 24"
            },
            timeSpan:{
                icon:trophyIconBlue,
                details:"100k / Winner"
            },
            prize:{
                icon:clockIconBlue,
                details:"36 hours Event"
            },
            participants:{
                icon:membersIconBlue,
                details:"3 - 5 PARTICIPANTS"
            }
        }
    }
}