export class HOME_CONSTANTS {

    static REWIND_SECTION = {
            when:"WHEN",
            humans:"HUMANS",
            and:"AND",
            ai:"AI",
            unite: "UNITE",
            descrp_1:'When humans and AI unite in collaboration, They don’t just merge, they create -',
            descrp_2:" A synergy of human ingenuinity and artificial intelligence, unlocking limitless possibilities for innovation and progress.",
            rewind_button:"REWIND TO 2023",
            rewind_url:"https://www.codesngears.com"
    }
}