import React, { useState, useEffect } from 'react';
import './styles.scss';
import CommitteeCard from '../CommitteCard';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/controller';

// import required modules
import { EffectCoverflow, Navigation,Keyboard, Pagination  } from 'swiper/modules'

  //import 'swiper/css/free-mode'
  ;

const CommitteeCardCarousel = ({ committeeCardInfo, setSwiper, setMembersDataIndex }) => {


  return (
    <div id="carousel-wrapper">
      <Swiper
        effect={'coverflow'}
        initialSlide={1}
        grabCursor={true}
        centeredSlides={true}
        roundLengths={true}
        slideToClickedSlide={true}
        loop={true}
        slidesPerView={2}
        slidesPerGroup={1}
        spaceBetween={70}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 400,
          modifier: 1,
        }}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        onSwiper={setSwiper}
        onSlideChange={(swiper) => {
          setMembersDataIndex(swiper.realIndex);
        }}
        modules={[EffectCoverflow, Navigation,Keyboard, Pagination]}
        navigation={{
          nextEl: '#swiper-button-next',
          prevEl: '#swiper-button-prev',
        }}
      >
        {committeeCardInfo.map((item, index) => (

          <SwiperSlide
            key={index}
            style={{ width: `17.5%` }}
            className="slide-wrapper"
          >
            {({ isActive }) => (
              <CommitteeCard {...item} isActive={isActive} />
            )}

          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CommitteeCardCarousel;
