import React, { useEffect, useState } from "react";
import "./styles.scss";
import HomepageNavbar from "../../components/HomepageNavbar";
import SideNavbar from "../../components/SideNavbar";
import header_bg from "../../assets/images/participants-header-box.svg"
import A_icon from "../../assets/icons/A-icon.svg"
import ParticipantsListContainer from "../../components/ParticipantsListContainer";
import {
    TEAM_DETAILS,
  } from "../../constants/app.constants";
const ParticipantsPage = () => {
    const [activeTab ,  setActiveTab] = useState("codeathon");

    const [showSideNavbar, setShowSideNavbar] = useState(false);
    const [displayOverlay, setDisplayOverlay] = useState(false);

    const handleButtonClick = () => {
        setShowSideNavbar(true);
        setDisplayOverlay(true);
    };
    
   const  filteredTeamDetails = TEAM_DETAILS.filter( i =>  i.type === activeTab);
    const changeTab = ( tabname) => {
        setActiveTab(tabname);
    }
    return (
        <div className="participants-page flex flex-col">
            <HomepageNavbar handleButtonClick={handleButtonClick} />
            <SideNavbar showSideNavbar={showSideNavbar} displayOverlay={displayOverlay} setDisplayOverlay={setDisplayOverlay} setShowSideNavbar={setShowSideNavbar} />
            <div className=" participants-page-container flex flex-col gap-8 items-center mt-[90px] header-align px-4 md:px-8 lg:px-20">
                <div className="min-w-[200px] relative md:max-w-[640px] gap-2 md:gap-8 flex justify-between items-center p-3 md:p-4">
                    <img src={header_bg} alt="bg" className="w-full h-full absolute top-0 right-0" />
                    <div className={` relative z-10 flex gap-2 items-center participants-header-item text-white font-goodTimes  md:text-2xl font-black p-2 md:p-5 rounded-lg md:rounded-xl cursor-pointer ${activeTab == "codeathon" ? "active"  : ""} `}
                    onClick={()=> {
                        changeTab("codeathon")
                    }}>
                        CODE <div className="h-4 w-4 md:w-8 md:h-8"><img src={A_icon} className="w-full h-full" /></div> THON
                    </div>
                    <div className={` relative z-10 flex gap-2 items-center participants-header-item text-white font-goodTimes  md:text-2xl font-black p-2 md:p-5 rounded-lg md:rounded-xl cursor-pointer ${activeTab == "thinkathon" ? "active"  : ""} `}
                    onClick={()=> {
                        changeTab("thinkathon")
                    }}>
                        THINK <div className=" h-4 w-4 md:w-8 md:h-8"><img src={A_icon} className="w-full h-full" /></div> THON
                    </div>
                </div>
                <ParticipantsListContainer details = {filteredTeamDetails}/>
            </div>
        </div>
    );
};
export default ParticipantsPage;
