// HomePage.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import PrevMoments from "../../components/PrevMomentsSection";
import mainLogoImage from "../../assets/logos/codesNgears.svg";
import thunderImage from "../../assets/images/thunder.webp";
import downButton from "../../assets/icons/down-button.svg";
import HomepageButton from "../../components/HomepageButton";
import XIEdition from "../../assets/logos/XIedition.svg";
import humans from "../../assets/icons/humans.svg";
import glassBg from "../../assets/images/glass-bg.svg";
import thinkAThonRep from "../../assets/icons/thinkathon-rep.svg";
import codeAThonRep from "../../assets/icons/code-a-thon-rep.svg";
import aIcon from "../../assets/icons/A-icon.svg";
import { HOME_CONSTANTS } from "../../constants/home.constants";
import line from "../../assets/icons/Line.svg";
import carotBtn from "../../assets/icons/carot-r-btn.svg";
import participants from "../../assets/icons/Participants.svg";
import useScreenSize from "../../core/shared/useScreenSize";
import HomeFooter from "../../components/HomeFooter";
import HomepageNavbar from "../../components/HomepageNavbar";
import SideNavbar from "../../components/SideNavbar";
import HomePageBackgroundClip from "../../assets/Videos/cngHomeBg.mp4";

const getDeskTopViewForHomePageEvents = (
  eventSectionRef,
  counter,
  handleEventButtonClick
) => {
  return (
    <div
      ref={eventSectionRef}
      className="home-page-events-container bg-left bg-no-repeat flex relative h-screen overflow-x-hidden"
    >
      <div className="w-5/12 p-12">
        <div className="relative h-full flex">
          <div className="flex flex-col h-2/3 justify-center ">
            <p className="font-goodTimesLightItalic bg-clip-text font-extralight text-2xl italic the-text leading-9 mb-2">
              THE
            </p>
            <p className="events-text font-goodTimesBold bg-clip-text text-8xl">
              EVENTS
            </p>
            <div className="w-full mt-5 border-white">
              <div className="w-full flex justify-end">
                <p className="w-full font-goodTimes know-more-btn flex justify-end items-center ">
                  <div className="glow-bar mr-4"></div>
                  <span className=" me-3 mr-2">KNOW MORE </span>
                  <img
                    className="mt-2 cursor-pointer"
                    src={carotBtn}
                    onClick={handleEventButtonClick}
                    alt="caro-btn"
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0">
            <p className="participant-count italic">
              <span className="text-130 font-black font-goodTimesBold">
                {counter}&nbsp;
              </span>
              <span className="text-plus font-normal">+</span>
            </p>
            <img src={participants} alt="participants" />
          </div>
        </div>
      </div>
      <div className="right-side w-7/12 bg-no-repeat h-screen flex absolute">
        <section className="blue-card w-1/2 overflow-hidden">
          <div className="top-section">
            <div className="top-content flex justify-end">
              <div className="icon-container p-8 rounded-bl-3xl">
                <img src={thinkAThonRep} alt="think a thon representation" />
              </div>
            </div>
            <div className="mid-content relative text-end">
              <p className="text-one font-goodTimesBold leading-none">24</p>
              <p className="text-two font-goodTimesBold text-9xl">HR</p>
            </div>
          </div>
          <div className="bottom-section">
            <div className="think-a-thon-wrapper w-3/4 h-full rounded-tr-3xl">
              <p className="flex h-full justify-center items-center">
                <span className="think font-black italic leading-9 font-goodTimesBold mr-3.5">
                  THINK
                </span>
                <img src={aIcon} alt="A for Think-A-Thon" />
                <span className="thon font-black italic leading-9 font-goodTimesBold bg-clip-text ml-2">
                  THON&nbsp;
                </span>
              </p>
            </div>
          </div>
        </section>
        <section className="black-border"></section>
        <section className="green-card w-1/2">
          <div className="top-section">
            <div className="top-content flex justify-center">
              <div className="icon-container p-8 rounded-bl-3xl">
                <img src={codeAThonRep} alt="code a thon representation" />
              </div>
            </div>
            <div className="mid-content text-center">
              <p className="text-one font-goodTimesBold leading-none">36</p>
              <p className="text-two text-end font-goodTimesBold text-9xl">
                HR
              </p>
            </div>
          </div>
          <div className="bottom-section">
            <div className="think-a-thon-wrapper w-3/4 h-full rounded-tr-3xl">
              <p className="flex h-full justify-center items-center">
                <span className="code font-black italic leading-9 font-goodTimesBold mr-3.5 bg-clip-text">
                  CODE
                </span>
                <img src={aIcon} alt="A for Code-A-Thon" />
                <span className="code-thon font-black italic leading-9 font-goodTimesBold text-transparent ml-2">
                  THON&nbsp;
                </span>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const getMobileViewForHomePageEvents = (
  eventSectionRef,
  counter,
  handleEventButtonClick
) => {
  return (
    <div
      ref={eventSectionRef}
      className="home-page-events-container bg-left bg-no-repeat relative h-[90vh] sm:h-[75vh] overflow-hidden"
    >
      <section className="pt-8">
        <div className="flex justify-center items-center">
          <p className="font-goodTimesLightItalic bg-clip-text font-extralight text-base the-text leading-4 me-4">
            THE
          </p>
          <p className="events-text font-goodTimesBold bg-clip-text text-4xl">
            EVENTS
          </p>
        </div>

        <div className="mt-5 p-6">
          <div className="flex w-full justify-end mt-3.5">
            <p className="font-goodTimes know-more-btn flex">
              <img className="mx-3 w-4" src={line} alt="line" />
              <span className="my-2 me-3">KNOW MORE </span>
              <img
                className="mt-1 cursor-pointer w-5"
                src={carotBtn}
                onClick={handleEventButtonClick}
                alt="caro-btn"
              />
            </p>
          </div>
        </div>
      </section>
      <section className="middle-section bg-no-repeat w-full h-2/4 relative">
        <div className="blue-card">
          <div className="mv-think-a-thon-txt-wrapper flex items-center justify-center">
            <span className="think font-black italic leading-9 font-goodTimesBold mr-3.5">
              THINK
            </span>
            <img src={aIcon} alt="A for Code-A-Thon" />
            <span className="thon font-black italic leading-9 font-goodTimesBold bg-clip-text ml-2">
              THON&nbsp;
            </span>
          </div>
          <div className="mv-think-a-thon-wrapper">
            <img src={thinkAThonRep} alt="think" />
          </div>
        </div>
        <div className="green-card">
          <div className="mv-code-a-thon-wrapper">
            <img src={codeAThonRep} alt="code-a-thon" />
          </div>
          <div className="mv-code-a-thon-txt-wrapper flex items-center justify-center">
            <span className="code font-black italic leading-9 font-goodTimesBold mr-3.5">
              CODE
            </span>
            <img src={aIcon} alt="A for Code-A-Thon" />
            <span className="code-thon font-black italic leading-9 font-goodTimesBold bg-clip-text ml-2">
              THON&nbsp;
            </span>
          </div>
        </div>
      </section>

      <div className="md:p-1 lg:p-10 flex justify-center items-center w-full participant-wrapper">
        <p className="participant-count italic me-3">
          <span className="text-130 font-black font-goodTimesBold">
            {counter}&nbsp;
          </span>
          <span className="text-plus font-normal">+</span>
        </p>
        <img src={participants} width={200} alt="participants" />
      </div>
    </div>
  );
};

export default function HomePage() {
  const [isHovered, setIsHovered] = useState(false);
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const navigate = useNavigate();
  const ref = useRef(null);
  // SCROLL TO REWIND
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleButtonHover = () => {
    setIsHovered(true);
  };

  const handleButtonLeave = () => {
    setIsHovered(false);
  };

  const handleDiscoverButtonClick = () => {
    navigate("/events");
  };

  const handleDownButtonClick = () => {};
  const handleButtonClick = () => {
    setShowSideNavbar(true);
    setDisplayOverlay(true);
  };

  const [counter, setCounter] = useState(1);
  const eventSectionRef = useRef(null);
  const screenSize = useScreenSize();
  const width = screenSize.width;

  const handleEventButtonClick = () => {
    // Navigate to the /events page when the button is clicked
    navigate("/events");
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const timer = setTimeout(() => {
            if (counter < 96) {
              setCounter(counter + 1);
            }
          }, 10);
          return () => clearTimeout(timer);
        }
      });
    });

    if (eventSectionRef.current) {
      observer.observe(eventSectionRef.current);
    }

    return () => {
      if (eventSectionRef.current) {
        observer.unobserve(eventSectionRef.current);
      }
    };
  }, [counter]);

  useEffect(() => {
    const video = document.querySelector(".homePageBackgroundClip");

    const handleVisibilityChange = () => {
      if (document.hidden) {
        video.pause();
      } else {
        video.play();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <div className="home-section-wrapper">
        <HomepageNavbar
          handleButtonClick={handleButtonClick}
          displayFaq="true"
        />
        <SideNavbar
          showSideNavbar={showSideNavbar}
          displayOverlay={displayOverlay}
          setDisplayOverlay={setDisplayOverlay}
          setShowSideNavbar={setShowSideNavbar}
        />
        <video
          autoPlay
          loop
          muted
          playsInline
          className="homePageBackgroundClip absolute z-[-1] w-[100vw] h-[100vh] object-cover"
        >
          <source src={HomePageBackgroundClip} type="video/mp4" />
        </video>

        <div className="card-left">
          <div className="bottom-card-part">
            <p>MAY 22 23 24</p>
          </div>
          <div className="top-card-part">
            <p>CDW Global</p>
          </div>
          <div className="left-curve"></div>
          <div className="right-curve"></div>
        </div>
        <div className="flex flex-col items-center justify-center  home-section h-full">
          <div className="logo-image-container w-[14rem] sm:w-[17.5rem] lg:w-[32rem] md:w-[25rem]">
            <p
              className={`glow-circle ${isHovered ? "glow-circle-hover" : ""}`}
            >
              O
            </p>
            <img
              className="object-contain w-full h-full logo-image-home"
              src={mainLogoImage}
              alt="Codes and Gears - 2024"
            />
          </div>
          <div
            className="mt-10 button-container-home lg:mt-20"
            onMouseEnter={handleButtonHover}
            onMouseLeave={handleButtonLeave}
          >
            <HomepageButton
              name={"discover more"}
              logoPosition={"left"}
              logo={<img src={thunderImage} alt="thunder img" />}
              onClick={handleDiscoverButtonClick}
            />
          </div>
          <div className="down-button" onClick={handleDownButtonClick}>
            <img
              className="object-contain w-full h-full"
              src={downButton}
              alt="Scroll Down"
              onClick={handleClick}
            />
          </div>
        </div>

        <div className="card-right">
          <div className="bottom-card-part card-right-br">
            <p>MAY 22 23 24</p>
          </div>
          <div className="top-card-part card-right-br">
            <p>CDW Global</p>
          </div>
          <div className="left-curve card-right-left-curve"></div>
          <div className="right-curve card-right-right-curve"></div>
        </div>
      </div>

      <div
        ref={ref}
        className="flex flex-col items-center w-full gap-12 py-10 pb-20 overflow-hidden rewind-section lg:gap-32"
      >
        <div className="flex flex-col-reverse items-center justify-between w-full gap-10 px-10 lg:px-20 lg:flex-row">
          <div className="h-24 lg:h-32">
            <img
              className="w-full h-full"
              src={XIEdition}
              alt="XI EDITION LOGO"
            />
          </div>
          <div className="flex flex-col gap-4 text-3xl md:text-4xl lg:text-5xl font-light text-center md:text-right lg:text-6xl font-goodTimesLight">
            <p className="flex gap-4">
              <span className="text-gradient-logo">
                {HOME_CONSTANTS.REWIND_SECTION.when}
              </span>
              <span className="text-heading-green">
                {HOME_CONSTANTS.REWIND_SECTION.humans}
              </span>
            </p>
            <p className="flex gap-4 justify-center  md:justify-end">
              <span className="text-gradient-logo">
                {HOME_CONSTANTS.REWIND_SECTION.and}
              </span>
              <span className="text-heading-blue">
                {HOME_CONSTANTS.REWIND_SECTION.ai}
              </span>
              <span className="text-gradient-logo">
                {HOME_CONSTANTS.REWIND_SECTION.unite}
              </span>
            </p>
          </div>
        </div>
        <div className="relative w-full text-white md:w-[82%] h-fit font-redRose h-full">
          <div className="absolute top-0 w-full h-full about-event-background">
            <img className="w-full h-full" src={glassBg} alt="GLASS BG" />
          </div>
          <div className="relative z-10 flex flex-col justify-center w-full h-full gap-8 px-5 py-10 leading-loose text-sm md:text-xl lg:text-3xl lg:px-20 lg:py-20 xl:px-56 xl:py-28 text-center">
            <p className="">
              <span className="inline">
                {HOME_CONSTANTS.REWIND_SECTION.descrp_1}
              </span>
              <img className="inline h-8 lg:h-10" src={humans} alt="HUMANS" />
            </p>
            <p>{HOME_CONSTANTS.REWIND_SECTION.descrp_2}</p>
          </div>
        </div>
      </div>

      {/* Home Page Events Start */}
      {width > 1024
        ? getDeskTopViewForHomePageEvents(
            eventSectionRef,
            counter,
            handleEventButtonClick
          )
        : getMobileViewForHomePageEvents(
            eventSectionRef,
            counter,
            handleEventButtonClick
          )}
      {/* Home Page Events END */}
      <PrevMoments />
      <HomeFooter />
    </>
  );
}
